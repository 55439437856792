import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { TableColumnHeader } from "./tableHeader";
import { Apifilters } from "../api/types";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { t } from "@lingui/macro";

const commonStyles = {
    height: '36px',
    color: "black",
    backgroundColor: 'white',
    borderRadius: '4px',
    '& .MuiSelect-select, & .MuiOutlinedInput-root': {
        color: "black",
        height: '100%',
        padding: '0 10px',
        borderRadius: '4px',
        fontSize: '0.875rem',
    },
    '& .MuiSelect-icon, & .MuiOutlinedInput-notchedOutline': {
        color: 'white',
    },
    '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#093a5e',
    },
};

export const FilterableCell = ({
    ch,
    filters,
    setFilter,
    columnOptions,
    inputType,
}: {
    ch: TableColumnHeader<any>;
    filters: Apifilters[];
    setFilter?: (id: string, value?: string | boolean, sortDecending?: boolean) => void;
    columnOptions?: string[];
    inputType: "text" | "select";
}) => {
    const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

    const handleSelectChange = (id: string, value: string, shouldSetFilter: boolean) => {
        setSelectedValues((prev) => ({ ...prev, [id]: value }));
        if (shouldSetFilter) {
            setFilter?.(id, value);
        }
    };

    const handleKeyPress = (id: string, event: React.KeyboardEvent, needEnter: boolean) => {
        if (needEnter && event.key === 'Enter') {
            setFilter?.(id, selectedValues[id]);
        } else if (!needEnter) {
            setSelectedValues((prev) => ({ ...prev, [id]: (event.target as HTMLInputElement).value }));
        }
    };

    const handleSortClick = () => {
        const sortDescending = filters.find((f) => f.column === ch.id)?.sortDescending ?? true;
        setFilter?.(ch.id as string, undefined, !sortDescending);
    };

    const value = selectedValues[ch.id as string] || '';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: ch.width }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleSortClick}
                >
                    {ch.label}
                </Typography>
                <SwapVertIcon
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleSortClick}
                />
            </Box>
            <FormControl sx={{ minWidth: ch.width, maxWidth: ch.width, width: ch.width, ...commonStyles }} size="small" variant="outlined">
                <InputLabel sx={{ color: "black" }} shrink={!!value}>
                </InputLabel>
                {inputType === "select" ? (
                    <Select
                        value={value}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value as string, true)}
                    >
                        {(!columnOptions || !columnOptions.includes("")) && (
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        )}
                        {columnOptions && columnOptions.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option === "" ? <em>{t`None`}</em> : option}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <TextField
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value, false)}
                        onKeyUp={(e) => handleKeyPress(ch.id as string, e, true)}
                        sx={{ ...commonStyles, width: ch.width }}
                        autoComplete="off"
                    />
                )}
            </FormControl>
        </Box>
    );
};

export default FilterableCell;
