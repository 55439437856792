import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import {
  getTaxonomySchemes,
  importTaxonomyFileByLocation,
  importTaxonomyFilesByLocation,
  importTaxonomyPackageByType,
} from "../../api/taxonomies";
import { isValidUrl } from "../../helpers/isValidUrl";
import {
  validateAllowedTaxImportFileExtension,
  validateAllowedTaxImportFileType,
} from "../../helpers/validateAllowedTaxImportFileType";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { getFileLocationHelperText } from "../../helpers/getFileLocationHelperText";
import { ApiSchemes } from "../../api/types";
import { getUserEmailValidationError } from "../../helpers/validateNewUser";

export type ImportType = "taxonomy" | "data";

export interface TaxonomyImportDialogProps {
  headerText: string;
  open: boolean;
  importType: ImportType | undefined;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => void;
}

const TaxonomyImportDialog = ({
  headerText,
  open,
  importType,
  onClose,
}: TaxonomyImportDialogProps) => {
  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<AxiosError | string>();
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [currentSystemName, setCurrentSystemName] = useState("");
  const [currentSystemType, setCurrentSystemType] = useState<
    "Default" | "EuroFilling"
  >("Default");

  const [settings, setSettings] = useState<{ email: string | null; logLevel: "info" | "debug" | null }>({
    email: null,
    logLevel: null,
  });
  const [currentFileOriginUrl, setCurrentFileOriginUrl] = useState("");
  const [currentFileOriginUrlError, setCurrentFileOriginUrlError] =
    useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentFileInputKey, setCurrentFileInputKey] = useState(
    crypto.randomUUID()
  );
  const [permissionScheme, setPermissionScheme] = useState<string>("");
  const [validatingAuthority, setValidatingAuthority] = useState<string | null>(null);

  const [allPermissionSchemes, setAllPermissionSchemes] = useState<
    ApiSchemes[]
  >([]);

  useEffect(() => {
    const fetchPermissionSchemes = async () => {
      const resp = await getTaxonomySchemes(crypto.randomUUID());
      setAllPermissionSchemes(resp.data);
    };
    if (open) {
      fetchPermissionSchemes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [missingFileNamesInImport, setMissingFileNamesInImport] = useState<
    string[]
  >([]);

  useEffect(() => {
    let isFormValid = false;
    if (currentFiles.length > 0 && missingFileNamesInImport.length === 0) {
      if (importType === "taxonomy") {
        if (currentSystemName.length > 0 && (currentSystemType !== "EuroFilling" || validatingAuthority)) {
          isFormValid = true;
        }
      } else if (importType === "data") {
        if (!currentFileOriginUrlError && currentFileOriginUrl.length > 5) {
          isFormValid = true;
        }
      }
    }
    setIsFormValid(isFormValid);
  }, [
    currentFiles,
    currentSystemName,
    currentFileOriginUrl,
    currentFileOriginUrlError,
    importType,
    missingFileNamesInImport,
    currentSystemType,
    validatingAuthority,
  ]);

  const handleRemoveFile = (fileName: string) => {
    setCurrentFiles((prev) => prev.filter((f) => f.name !== fileName));
  };

  const handleClear = () => {
    setCurrentFiles([]);
    setCurrentSystemName("");
    setCurrentSystemType("Default");
    setCurrentFileOriginUrl("");
    setCurrentFileOriginUrlError(false);
    setCurrentFileInputKey(crypto.randomUUID());
    setMissingFileNamesInImport([]);
    setValidatingAuthority(null);
    setSettings({ email: null, logLevel: null });
  };

  const handleClose = (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => {
    if (abortController) {
      abortController.abort();
    }
    handleClear();
    onClose(updateStatus, reason);
  };

  const handleFileSelected = (files: FileList | null) => {
    if (files && files.length > 0) {
      const fileArray = Array.from(files);
      let isValid;
      for (const file of fileArray) {
        if (file.type.length > 0) {
          isValid = validateAllowedTaxImportFileType(file.type, importType);
        } else {
          isValid = validateAllowedTaxImportFileExtension(
            file.name.split(".")[1],
            importType
          );
        }
        if (!isValid) {
          break;
        }
      }
      if (!isValid) {
        setUploadError(
          t`One or more of the selected file has an Invalid file type`
        );
      } else {
        setCurrentFiles((prev) => [...prev, ...fileArray]);
        setMissingFileNamesInImport([]);
      }
    }
  };

  const handleImportResults = (resp: AxiosResponse<string[]>) => {
    if (resp.data && resp.data.length > 0) {
      setMissingFileNamesInImport(resp.data);
    } else {
      handleClose("success");
    }
  };

  const handleChange = async (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setPermissionScheme(value as string);
  };

  const handleSubmit = async () => {
    try {
      const controller = new AbortController();
      setUploading(true);
      setUploadError(undefined);
      setAbortController(controller);
      setMissingFileNamesInImport([]);
      let resp: AxiosResponse<string[]>;
      if (importType === "taxonomy") {
        resp = await importTaxonomyPackageByType(
          currentSystemName,
          currentSystemType,
          currentFiles[0],
          controller.signal,
          permissionScheme,
          currentSystemType === "EuroFilling" ? validatingAuthority ?? undefined : undefined,
          settings.email || "",
          settings.logLevel || "",
        );
        handleImportResults(resp);
      } else if (importType === "data") {
        let resp;
        if (currentFiles.length > 1) {
          resp = await importTaxonomyFilesByLocation(
            currentFiles,
            currentFileOriginUrl,
            controller.signal
          );
        } else {
          resp = await importTaxonomyFileByLocation(
            currentFiles[0],
            currentFileOriginUrl,
            controller.signal
          );
        }
        handleImportResults(resp);
      }
    } catch (ex: any) {
      setUploadError(
        ex?.response?.data?.IsTranslated
          ? ex?.response?.data?.Exceptions[0]
          : t`There was an error processing your file. Try again later`
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        onClose={() => handleClose("none")}
      >
        <>
          <DialogTitle>
            {!uploading ? headerText : <Trans>Uploading Taxonomy file</Trans>}
          </DialogTitle>
          <DialogContent sx={{ minHeight: 210 }}>
            <Stack spacing={2}>
              {uploading && (
                <DialogContentText>
                  <Trans>Hang in there, This might take a few minutes...</Trans>
                </DialogContentText>
              )}
              {!uploading && (
                <>
                  <Button variant="outlined" component="label">
                    <Trans>Select File/s</Trans>
                    <input
                      key={currentFileInputKey}
                      hidden
                      type="file"
                      multiple={importType === "data"}
                      onChange={(e) => {
                        handleFileSelected(e.target.files);
                      }}
                    />
                  </Button>
                  {missingFileNamesInImport.length === 0 && (
                    <>
                      {importType === "data" && (
                        <TextField
                          sx={{ mt: 1 }}
                          value={currentFileOriginUrl}
                          onChange={(e) => {
                            setCurrentFileOriginUrl(e.currentTarget.value);
                            setCurrentFileOriginUrlError(
                              !isValidUrl(e.currentTarget.value)
                            );
                          }}
                          label={t`Official location`}
                          error={currentFileOriginUrlError}
                          helperText={getFileLocationHelperText(
                            currentFiles.length,
                            importType
                          )}
                        />
                      )}
                      {importType === "taxonomy" && (
                        <>
                          <TextField
                            sx={{ mt: 1 }}
                            value={currentSystemName}
                            onChange={(e) =>
                              setCurrentSystemName(e.currentTarget.value)
                            }
                            label={t`System Name`}
                          />
                          <FormControl>
                            <InputLabel id="taxonomy_import_select_system_type_label">
                              <Trans>System Type</Trans>
                            </InputLabel>
                            <Select
                              labelId="taxonomy_import_select_system_type_label"
                              value={currentSystemType}
                              label={t`System Type`}
                              onChange={(e) => {
                                setCurrentSystemType(e.target.value as any);
                                if (e.target.value !== "EuroFilling") {
                                  setValidatingAuthority(null);
                                }
                              }}
                            >
                              <MenuItem value="Default" key="Default">
                                <Trans>Default</Trans>
                              </MenuItem>
                              <MenuItem value="EuroFilling" key="EuroFilling">
                                <Trans>Euro Filling</Trans>
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl sx={{ mt: 1, minWidth: 300 }} fullWidth>
                            <InputLabel id="validating-authority-label" sx={{ backgroundColor: "white" }}>
                              {t`Validating Authority`}
                            </InputLabel>
                            <Select
                              labelId="validating-authority-label"
                              value={validatingAuthority || ""}
                              onChange={(e) => setValidatingAuthority(e.target.value)}
                            >
                              <MenuItem value="EBA">EBA</MenuItem>
                              <MenuItem value="EIOPA">EIOPA</MenuItem>
                              <MenuItem value="DNB">DNB</MenuItem>
                              <MenuItem value="SBR">SBR</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputLabel id="permission-schemes-label" sx={{ backgroundColor: "white" }}>
                              {t`Permission Schemes`}
                            </InputLabel>
                            <Select
                              labelId="permission-schemes-label"
                              value={permissionScheme as any}
                              onChange={handleChange}
                            >
                              {allPermissionSchemes.map((scheme) => (
                                <MenuItem key={scheme.id} value={scheme.id}>
                                  {scheme.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <TextField
                              label={t`Email for updates`}
                              value={settings.email || ""}
                              error={
                                settings.email !== null &&
                                getUserEmailValidationError(settings.email).length > 0
                              }
                              onChange={(e) => {
                                setSettings((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                              }}
                            />
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <InputLabel id="email-update-notifications " sx={{ backgroundColor: "white" }}>
                              {t`Email update notifications`}
                            </InputLabel>
                            <Select
                              disabled={settings.email === null || (settings.email !== null &&
                                getUserEmailValidationError(settings.email).length > 0)}
                              labelId="email-update-notifications"
                              value={settings.logLevel || ""}
                              onChange={(e) => {
                                console.log(settings)
                                setSettings((prev) => ({
                                  ...prev,
                                  logLevel: e.target.value as "info" | "debug",
                                }));
                              }}
                            >
                              <MenuItem value={"info"}>{t`Only when import is finished`}</MenuItem>
                              <MenuItem value={"debug"}>{t`Receive updates throughout`}</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                  {missingFileNamesInImport.length > 0 && (
                    <List
                      subheader={
                        <Trans>
                          The import taxonomy is missing the following files:
                        </Trans>
                      }
                    >
                      {missingFileNamesInImport.map((fileName) => (
                        <ListItem>
                          <ListItemIcon>
                            <ErrorOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary={fileName} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {currentFiles.length > 0 && (
                    <List>
                      {currentFiles.map((file) => (
                        <ListItem
                          secondaryAction={
                            <IconButton
                              edge="end"
                              onClick={() => handleRemoveFile(file.name)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={file.name} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                (currentFiles.length === 0 && !currentSystemName) || uploading
              }
              variant="outlined"
              onClick={handleClear}
              sx={{ mr: 43 }}
            >
              <Trans>Clear</Trans>
            </Button>

            <Button variant="outlined" onClick={() => handleClose("none")}>
              {uploading ? t`Cancel` : t`Close`}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!isFormValid || uploading || (settings.email !== null &&
                getUserEmailValidationError(settings.email).length > 0)}
            >
              {!uploading && <Trans>Save</Trans>}
              {uploading && <CircularProgress size={25} />}
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <Snackbar
        open={uploadError !== undefined}
        autoHideDuration={6000}
        onClose={() => setUploadError(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">
          {uploadError instanceof AxiosError
            ? uploadError.message
            : uploadError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TaxonomyImportDialog;