import { t } from "@lingui/macro";
import { ColumnProps } from "../../../api/types";
import DataTable from "../../dataTable";
import LabeledTextField from "../../labeledTextField/labeledTextField";
import { Box, Button } from "@mui/material";


export const TaxonomyViewer = (data: any) => {

    const Tablecolumns: () => ColumnProps[] = () => {
        return [
            {
                header: {
                    id: "name",
                    label: t`Name`,
                    optionsHeaderText: t`Name`,
                    shouldBeFiltered: true,
                    inputType: "text",
                    width: 600,
                },
                key: "name",
                width: 600,
            },
            {
                header: {
                    id: "nameSpace",
                    label: t`Name Space`,
                    shouldBeFiltered: true,
                    optionsHeaderText: t`Select Name Space`,
                    inputType: "select",
                    width: 150,
                },
                key: "nameSpace",
                width: 150,
            },
            {
                header: {
                    id: "periodType",
                    label: t`Period Type`,
                    shouldBeFiltered: true,
                    optionsHeaderText: t`Select Period Type`,
                    inputType: "text",
                    width: 250,
                },
                key: "periodType",
                width: 250,
            },
            {
                header: {
                    id: "isCredit",
                    label: t`Is Credit`,
                    shouldBeFiltered: true,
                    inputType: "select",
                    width: 250,
                },
                key: "isCredit",
                width: 250,
            },
        ];
    };

    return (
        <>
            <Box>
                <Box sx={{ mt: 5, alignContent: "center", display: "flex", flexDirection: "row" }}>
                    <LabeledTextField
                        label={t`Entrypoint`}
                        fieldType="select"
                        value=""
                        onValueChange={() => { }}
                    />
                    <LabeledTextField
                        label={t`Language`}
                        fieldType="select"
                        value=""
                        onValueChange={() => { }}
                    />
                </Box>
                <Box sx={{ mb: 1, mt: 2, alignContent: "center", display: "flex", flexDirection: "row" }}>

                    <LabeledTextField
                        label={t`Search label`}
                        fieldType="text"
                        value=""
                        onValueChange={() => { }}
                    />
                    <Button variant="outlined">{t`Search`}</Button>
                </Box>
                <DataTable
                    data={[]}
                    columns={Tablecolumns()}
                    noWidth
                    noDataMessage={t`No Taxonomy Elements`}
                />
            </Box>
        </>)
};