import { Box, Container } from "@mui/material";
import { DimensionValue } from "../../api/types";
import Grid from "@mui/material/Grid2";

interface ElementDetailedViewProps {
    element: DimensionValue;
}

const GridView = ({ label, value, size }: { label: string, value: string, size: number }) => {
    return (
        <Grid size={size} sx={{
            overflow: "hidden",
        }}>
            <b>
                {label}:
            </b>
            {value}
        </Grid>
    );
}

export const DimensionDetailedView = ({
    element
}: ElementDetailedViewProps) => {
    return (
        <Container
            maxWidth={"lg"}
            sx={{
                maxHeight: 150,
                display: "flex",
                wordWrap: "break-word",
            }}
        >
            {element?.isCustom ? (
                <>
                    <Grid container wrap="nowrap">
                        <GridView label="Invert Sign" value={element.customDimensionValue?.isDefaultMember ? "Yes" : "No"} size={8} />
                        <GridView label="is Credit" value={element.customDimensionValue?.dimensionName || ""} size={4} />
                        <GridView label="Wider" value={element.customDimensionValue?.dimension?.name || ""} size={6} />
                    </Grid>
                    <Grid container wrap="nowrap" size={12}>
                        <GridView label="Company" value={element.customDimensionValue?.dimensionName || ""} size={6} />
                        <GridView label="Taxonomies" value={element.customDimensionValue?.dimensionName || ""} size={6} />
                        <GridView label="Narrower" value={element.customDimensionValue?.dimensionName || ""} size={6} />
                    </Grid>
                </>
            ) : (
                <Box>{element.name || ""}</Box>
            )}
        </Container>
    );
}