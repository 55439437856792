import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { ApiConversion } from "../../api/types";
import { DataTableColumn } from "../dataTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { Chip, Stack, Typography } from "@mui/material";
import { getStatusColor } from "../../helpers/getConversionStatusColoer";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";
import TimerDisplayText from "../timerDisplayText";

export function conversionTableColumns(): DataTableColumn<ApiConversion>[] {
  return [
    {
      header: {
        id: "id",
        label: t`ID`,
      },
      key: "id",
      width: 300,
    },
    {
      header: {
        id: "appId",
        label: t`APPLICATION`,
      },
      key: "appId",
      width: 140,
    },
    {
      header: {
        id: "organizationName",
        label: t`ORGANIZATION`,
      },
      width: 240,
      key: "organizationName",
    },
    {
      header: {
        id: "userName",
        label: t`USER`,
      },
      width: 230,
      key: "userName",
    },
    {
      header: {
        id: "startedAt",
        label: t`START`,
      },
      key: "startedAt",
      valueFormatter: (row) =>
        moment(row.startedAt?.toString() || "").format("DD/MM/YYYY HH:mm:ss"),
      width: 165,
    },
    {
      header: {
        id: "elapsedTime",
        label: t(i18n)`TIME`,
      },
      key: "elapsedTime",
      width: 100,
      valueFormatter: (row) =>
        row.status === "Running" ? (
          <TimerDisplayText startValue={row.startedAt?.toString()} />
        ) : (
          row.stepsElapsedTime?.split(".")[0] || ""
        ),
    },
    {
      header: {
        id: "counters_steps",
        label: t(i18n)`STEPS`,
      },
      key: "counters_steps",
      valueFormatter: (row) => row.counters.steps.toString(),
      width: 50,
    },
    {
      header: {
        id: "counters_messaging_warnings",
        label: t(i18n)`WARNINGS`,
      },
      key: "counters_messaging_warnings",
      width: 120,
      valueFormatter: (row) => (
        <Stack direction="row" spacing={1}>
          <Typography>{row.counters.messaging.warnings}</Typography>
          <Typography>
            {row.counters.messaging.warnings > 0 && (
              <WarningIcon
                sx={{ color: (theme) => theme.palette.warning.main }}
              />
            )}
            {row.counters.messaging.warnings === 0 && (
              <CheckCircleIcon
                sx={{ color: (theme) => theme.palette.success.main }}
              />
            )}
          </Typography>
        </Stack>
      ),
    },
    {
      header: {
        id: "counters_messaging_errors",
        label: t(i18n)`ERRORS`,
      },
      key: "counters_messaging_errors",
      width: 100,
      valueFormatter: (row) => (
        <Stack direction="row" spacing={1}>
          <Typography>{row.counters.messaging.errors}</Typography>
          <Typography>
            {row.counters.messaging.errors > 0 && (
              <ErrorIcon sx={{ color: (theme) => theme.palette.error.main }} />
            )}
            {row.counters.messaging.errors === 0 && (
              <CheckCircleIcon
                sx={{ color: (theme) => theme.palette.success.main }}
              />
            )}
          </Typography>
        </Stack>
      ),
    },
    {
      header: {
        id: "status",
        label: t(i18n)`STATUS`,
      },
      width: 140,
      key: "status",
      valueFormatter: (row) => (
        <Chip
          sx={{ width: 100 }}
          label={row.status.toUpperCase()}
          variant="filled"
          size="small"
          color={getStatusColor(row.status)}
        />
      ),
    },
    {
      header: {
        id: "",
        label: "",
      },
      key: "copy_id",
      width: 70,
      shouldWrapVertically: true,
      valueFormatter: (row) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(row.id);
          }}
          title={t`Copy conversion id to clipboard`}
        >
          <ContentCopyIcon />
        </IconButton>
      ),
    },
    {
      header: {
        id: "",
        label: "",
      },
      key: "redirect_id",
      width: 70,
      shouldWrapVertically: true,
      valueFormatter: (row) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            window.open(`/conversions/${row.id}`);
          }}
          title={t`Go to conversion page`}
        >
          <LaunchIcon />
        </IconButton>
      ),
    },
  ];
}
