import {
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { TaxonomyListProps } from "../../api/types";
import { t } from "@lingui/macro";
import { getAllTaxonomies, handleTaxonomiesChange } from "../../helpers/taxonomyHelpers";

interface SelectingTaxonomyProps {
  open: boolean;
  isDialog: boolean;
  onClose: () => void;
  onApply: (selectedTaxonomies: TaxonomyListProps[]) => void;
  preSelectedTaxonomies?: TaxonomyListProps[];
}

const SelectTaxonomies = ({
  open,
  isDialog,
  onClose,
  onApply,
  preSelectedTaxonomies = [],
}: SelectingTaxonomyProps) => {
  return (
    <>
      {isDialog ? (
        <Container maxWidth={false} sx={{ p: 0, m: 0 }}>
          <Dialog open={open} onClose={onClose} fullWidth>
            <Container sx={{ mt: 5, mb: 5 }}>
              <MainTaxonomySelector
                isDialog={isDialog}
                open={open}
                onClose={onClose}
                onApply={onApply}
                preSelectedTaxonomies={preSelectedTaxonomies}
              />
            </Container>
          </Dialog>
        </Container>
      ) : (
        <Container
          sx={{
            p: 0,
            m: 0,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <MainTaxonomySelector
            isDialog={isDialog}
            open={open}
            onClose={onClose}
            onApply={onApply}
            preSelectedTaxonomies={preSelectedTaxonomies}
          />
        </Container>
      )}
    </>
  );
};

const MainTaxonomySelector = ({
  isDialog,
  onApply,
  onClose,
  preSelectedTaxonomies,
}: SelectingTaxonomyProps) => {
  const [localTaxonomies, setLocalTaxonomies] = useState<TaxonomyListProps[]>([]);
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<TaxonomyListProps[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTaxonomies = async () => {
      try {
        const allTaxonomies = await getAllTaxonomies();

        const updatedTaxonomies = allTaxonomies.map((taxonomy) => ({
          ...taxonomy,
          isSelected: preSelectedTaxonomies?.some((preTax) => preTax.id === taxonomy.id) || taxonomy.isSelected || false,
        }));

        setLocalTaxonomies(updatedTaxonomies);

        const initialSelectedTaxonomies = updatedTaxonomies.filter((taxonomy) => taxonomy.isSelected);

        setSelectedTaxonomies(initialSelectedTaxonomies);
      } catch (error) {
        setError(t`Failed to load taxonomies. Please try again later.`);
      }
    };

    fetchTaxonomies();
  }, [preSelectedTaxonomies]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const updatedTaxonomies = localTaxonomies.map((taxonomy) => {
      if (taxonomy.id === value) {
        return {
          ...taxonomy,
          isSelected: checked,
        };
      }
      return taxonomy;
    });

    setLocalTaxonomies(updatedTaxonomies);

    const updatedSelectedTaxonomies = checked
      ? [...selectedTaxonomies, updatedTaxonomies.find((taxonomy) => taxonomy.id === value)!]
      : selectedTaxonomies.filter((taxonomy) => taxonomy.id !== value);

    setSelectedTaxonomies(updatedSelectedTaxonomies);
    if (!isDialog) {
      handleTaxonomiesChange(updatedTaxonomies);
      onApply(updatedSelectedTaxonomies);
    }
  };

  const handleApply = () => {
    onApply(selectedTaxonomies);
    onClose();
  };

  return (
    <Container sx={{ m: 0, p: 0 }}>
      <Typography variant="h5">{t`Select Taxonomies`}</Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <FormControl fullWidth>
          <FormGroup>
            {localTaxonomies
              .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
              .map(({ id, isSelected, name }) => (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={handleChange}
                      value={id}
                      sx={{ p: 0.5 }}
                    />
                  }
                  label={name || id}
                  sx={{ m: 0 }}
                />
              ))}
          </FormGroup>
        </FormControl>
      )}
      {isDialog && (
        <Container sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button variant="outlined" onClick={onClose}>
            {error ? t`Close` : t`Cancel`}
          </Button>
          {!error && (
            <Button sx={{ ml: 2 }} variant="contained" onClick={handleApply}>
              {t`Apply`}
            </Button>
          )}
        </Container>
      )}
    </Container>
  );
};

export default SelectTaxonomies;