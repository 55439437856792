import { CustomObjectsPageComponent } from "../../customObjects/customObjectsPageComponent";


export const TaxonomyDimensionValues = (data: any, taxonomyId: string) => {


    return (
        <CustomObjectsPageComponent
            url="taxonomydimensionvalues"
            headerTitle={""}
            selectTemplate="taxonomyDimensionValues"
            onUpdate={(updateStatus, reason) => {
                // handleAlertUpdate(reason, updateStatus);
            }}
            taxonomyChanged={false}
            setTaxonomyChanged={() => { }}
        />
    )
};