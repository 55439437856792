import {
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  Typography,
  TableCellProps,
  Box,
} from "@mui/material";
import { DataTableColumn } from "./dataTable";
import { Apifilters } from "../api/types";
import FilterableCell from "./filterableCell";

export interface TableColumnHeader<T> {
  id: keyof T | string;
  label: string;
  width?: number;
  optionsHeaderText?: string;
  options?: string[];
  shouldBeFiltered?: boolean;
  inputType?: "text" | "select";
}

export interface TableHeaderProps<T> {
  column: DataTableColumn<T>;
  columnHeaders: TableColumnHeader<T>[];
  expandable?: boolean;
  nested?: boolean;
  filters: Apifilters[];
  setFilter?: (id: string, value?: string | boolean, sortDecending?: boolean) => void;
}

interface StyledTableCellProps extends TableCellProps {
  width?: number;
  nested?: boolean;
}

const StyledTableCell = styled((props: StyledTableCellProps) => {
  const { nested, width, ...nativeProps } = props;
  return (
    <TableCell sx={{ width: width ? width : undefined }} {...nativeProps}>
      {nativeProps.children}
    </TableCell>
  );
})(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#072a44",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export default function TableHeader<T>({
  columnHeaders,
  expandable,
  setFilter,
  filters,
  nested,
}: TableHeaderProps<T>) {
  return (
    <TableHead>
      <TableRow>
        {expandable && <StyledTableCell nested={nested}></StyledTableCell>}
        {columnHeaders.map((ch, index) => {
          const columnOptions = ch.options ?? filters.find(f => f.column === ch.id)?.values ?? undefined;
          const inputType = ch.inputType ?? "text";
          const isFiltered = setFilter && ch.shouldBeFiltered;

          return (
            <StyledTableCell key={`head_column_${index}`} width={ch.width} sx={{ color: 'white', width: ch.width }}>
              {expandable && index === 0 && <Box sx={{ display: 'flex', flexDirection: 'column', width: ch.width }}></Box>}
              {isFiltered ? (
                <FilterableCell
                  ch={ch}
                  filters={filters}
                  setFilter={setFilter}
                  columnOptions={columnOptions as string[]}
                  inputType={inputType}
                />
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: ch.width }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={"bold"}
                      sx={{ cursor: 'default' }}
                    >
                      {ch.label}
                    </Typography>
                  </Box>
                </Box>
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
