import { useEffect, useState } from "react";
import { Box, Typography, Divider, Container, Button, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { ApiDetailedTaxonomy, ApiSchemes } from "../../../api/types";
import LabeledTextField from "../../labeledTextField/labeledTextField";
import { DataGrid, GridRowModesModel, GridColDef } from "@mui/x-data-grid";
import { t } from "@lingui/macro";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const initialLabels = [
    { id: 1, lang: "da", text: "DK IFRS 20211220" },
    { id: 2, lang: "en", text: "DK IFRS 20211220" },
    { id: 3, lang: "da", text: "DK IFRS" },
    { id: 4, lang: "en", text: "DK IFRS" },
];

interface TaxonomyDetailsProps {
    data: ApiDetailedTaxonomy;
    setData: (data: ApiDetailedTaxonomy) => void;
    taxonomySchemes: ApiSchemes[];
    permissionScheme: ApiDetailedTaxonomy["permissionScheme"] | null;
    setPermissionScheme: (scheme: ApiDetailedTaxonomy["permissionScheme"] | null) => void;
}

const DISPLAY_TO_BACKEND_MAPPING: { [key: string]: string } = {
    "Euro Fillings": "EuroFilling",
};

const BACKEND_TO_DISPLAY_MAPPING: { [key: string]: string } = {
    "EuroFilling": "Euro Fillings",
};

export const TaxonomyDetails = ({ data, setData, taxonomySchemes, permissionScheme, setPermissionScheme }: TaxonomyDetailsProps) => {

    const [detailData, setDetailData] = useState<HardCodedData | null>(null);
    const [originalTitle, setOriginalTitle] = useState("");

    //TODO implement api call - for now hardcoded data is used
    useEffect(() => {
        setDetailData(hardCodedData);
    }, []);

    const handleFieldValueChange = (key: keyof ApiDetailedTaxonomy | string | undefined, value: string) => {
        if (key) {
            if (!originalTitle) {
                setOriginalTitle(data?.systemName || "");
            }
            const updated = { ...data };
            if (key === "type") {
                const mappedValue = DISPLAY_TO_BACKEND_MAPPING[value] || value;
                (updated as any)[key] = mappedValue;
                if (mappedValue === "Default") {
                    updated.validatingAuthority = null;
                }
            } else {
                (updated as any)[key] = value;
            }
            setData(updated as ApiDetailedTaxonomy);
        }
    };

    const [rows, setRows] = useState(initialLabels);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleRowModesModelChange = (newModel: GridRowModesModel) => {
        setRowModesModel(newModel);
    };

    const handleRowEditStop = (params: any, event: any) => {
        event.defaultMuiPrevented = true;
    };

    const processRowUpdate = (newRow: any) => {
        setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const handleDelete = (id: number) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const columns: GridColDef[] = [
        {
            field: "lang",
            headerName: "",
            flex: 1,
            resizable: false,
            renderHeader: () => (
                <Button color="primary" onClick={() => alert('Language header clicked')}>
                    Language
                </Button>
            ),
            renderCell: (params) => (
                <Select
                    value={params.value}
                    sx={{ width: "100%", boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    onChange={(e) => {
                        const updatedRow = { ...params.row, lang: e.target.value };
                        processRowUpdate(updatedRow);
                    }}
                >
                    <MenuItem value="da">Danish</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                </Select>
            ),
            editable: true,
        },
        {
            field: "text",
            headerName: "",
            flex: 5,
            resizable: false,
            renderHeader: () => (
                <Button color="primary" onClick={() => alert('Text header clicked')}>
                    Text
                </Button>
            ),
            renderCell: (params) => (
                <TextField
                    sx={{ width: "100%", }}
                    value={params.value}
                    onChange={(e) => {
                        const updatedRow = { ...params.row, text: e.target.value };
                        processRowUpdate(updatedRow);
                    }}
                />
            ),
            editable: true,
        },
        {
            field: "actions",
            headerName: "",
            flex: 0,
            sortable: false,
            filterable: false,
            resizable: false,
            renderHeader: () => (
                <Tooltip title={t`Add new row`}>
                    <AddIcon
                        onClick={() => alert('Actions header clicked')}
                        sx={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            ),
            renderCell: (params) => (
                <Tooltip title={t`Delete row`}>
                    <DeleteIcon
                        onClick={() => handleDelete(params.row.id)}
                        sx={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            ),
            align: 'right',
            headerAlign: 'right',
        },
    ];


    interface Icolumns {
        label: string;
        key: string;
        value: string | undefined;
        select?: boolean;
        options?: string[];
        isCheckBox?: boolean;
        disabled?: boolean;
        onchange?: boolean;
    }

    const ColumnItems: Icolumns[] = [
        { label: "Id:", key: "id", value: data?.id, disabled: true },
        { label: "System Name:", key: "systemName", value: data?.systemName, onchange: true },
        { label: "Package Name:", key: "packageName", value: data?.packageName, disabled: true },
        { label: "Identifier:", key: "identifier", value: data?.identifier, disabled: true },
        { label: "Full Package Name:", key: "fullPackageName", value: data?.fullPackageName, disabled: true },
        { label: "System Type:", key: "type", value: BACKEND_TO_DISPLAY_MAPPING[data?.type ?? ""] || data?.type, select: true, options: ["Default", "Euro Fillings"], onchange: true },
        { label: "License Name:", key: "licenseName", value: data?.license?.name, disabled: true },
        { label: "Validating Authority:", key: "validatingAuthority", value: data?.validatingAuthority || "", select: true, options: (BACKEND_TO_DISPLAY_MAPPING[data?.type ?? ""] || data?.type) === "Euro Fillings" ? ["EBA", "EIOPA", "DNB", "SBR"] : [""], disabled: (BACKEND_TO_DISPLAY_MAPPING[data?.type ?? ""] || data?.type) !== "Euro Fillings", onchange: true },
        { label: "License Href:", key: "licenseHref", value: data?.license?.href, disabled: true },
    ];

    return (
        <>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Typography variant="h6">{t`General Information`}</Typography>
                <Divider />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {ColumnItems.map((item) => (
                    <Box sx={{ flex: "1 1 50%" }} key={item.key}>
                        <LabeledTextField
                            label={item.label}
                            value={item.value}
                            disabled={item.disabled}
                            fieldType={item.select ? "select" : "text"}
                            options={item.options}
                            onValueChange={item.onchange ? (e: any) => handleFieldValueChange(item.key, e.target.value) : undefined}
                        />
                    </Box>
                ))}
                <Box sx={{ flex: "1 1 50%" }}>
                    <LabeledTextField
                        label="Permission Scheme"
                        value={permissionScheme?.name || ""}
                        fieldType="select"
                        options={["", ...taxonomySchemes.map((item) => item.name)]}
                        onValueChange={(e: any) => {
                            if (e.target.value === "") {
                                setPermissionScheme(null);
                            } else {
                                const selected = taxonomySchemes.find((item) => item.name === e.target.value);
                                if (selected) {
                                    setPermissionScheme({ id: selected.id, name: selected.name });
                                }
                            }
                        }}
                    />
                </Box>
            </Box>
            <Container maxWidth={false}>
                <Box sx={{ pt: 2, pb: 2 }}>
                    <LabeledTextField
                        label={t`Validation Ruleset`}
                        value={detailData?.validationRuleset}
                        fieldType="searchSelect"
                        onValueChange={(e: any) => handleFieldValueChange("validationRuleset", e.target.value)}
                        options={["EIOPA", "EBA", "NT"]}
                    />
                    <LabeledTextField
                        label={t`Strict Parsing`}
                        value={detailData?.strictParsing}
                        fieldType="checkbox"
                        onValueChange={(e: any) => handleFieldValueChange("strictParsing", e.target.value)}
                    />
                    <LabeledTextField
                        label={t`Real TableNs`}
                        value={detailData?.useRealTableNamespace}
                        fieldType="checkbox"
                        onValueChange={(e: any) => handleFieldValueChange("useRealTableNamespace", e.target.value)}
                    />
                </Box>
                <Box sx={{ pt: 2, pb: 2 }}>
                    <Typography variant="h6">{t`Labels`}</Typography>
                    <Divider />
                </Box>
                <DataGrid
                    sx={{ height: 400, width: '100%' }}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{}}
                />
            </Container>
        </>
    );
};

interface HardCodedData {
    defaultValues: any[];
    contextSettings: {
        context: string;
        period: string;
        taxonomies: any[];
        dimensionValueReferences: {
            taxonomyValue: {
                dimensionReference: {
                    namespaceAbbreviation: string;
                    name: string;
                    description: string;
                };
                dimensionValueReference: {
                    namespaceAbbreviation: string;
                    name: string;
                    description: string;
                };
            };
            customDimensionValue: any;
            onlyWhenConsolidated: any;
            isCustom: boolean;
            dimensionName: string;
            valueName: string;
            name: string;
        }[];
        id: string;
    }[];
    entryPoints: string[];
    identifierElementId: any;
    identifierElementName: any;
    reportTypeElementId: any;
    reportTypeElementName: any;
    languages: string[];
    taxonomyLanguages: string[];
    settings: {
        taxonomies: any[];
        settingName: string;
        required: boolean;
        labels: {
            lang: string;
            text: string;
            role: string;
            id: string;
        }[];
        id: string;
    }[];
    elementsToLog: any[];
    settingsToLog: any[];
    allowEmptyLog: boolean;
    dimensionValuesToIgnore: any[];
    importId: any;
    published: boolean;
    forceValidate: boolean;
    disclosureSystem: any;
    namespaces: {
        abbreviation: string;
        href: string;
        id: string;
    }[];
    dimensions: any[];
    dimensionValues: any[];
    visible: boolean;
    rewrites: {
        uri: string;
        rewrite: string;
    }[];
    baseUri: string;
    useRealTableNamespace: boolean;
    strictParsing: boolean;
    overrideCurrencyDimensionValue: any;
    overrideCurrencyDimension: any;
    standalone: boolean;
    hasExternalValidation: boolean;
    validationRuleset: any;
    isMappingTaxonomy: boolean;
    mappingTaxonomyId: any;
    isReferenceTaxonomy: boolean;
    hypercubeForLineItemsWithoutDimensions: any;
    defaultDimensionRole: any;
    taxonomyExtensionBuilder: any;
    isAnnualTaxonomy: boolean;
    taxonomyPackagePath: any;
    additionalEntrypoints: any;
    validator: any;
    extensionNamingConventions: {
        entrypointFile: string;
        labelFile: string;
        calculationsFile: string;
        presentationFile: string;
        definitionFile: string;
        documentationFile: string;
        referenceFile: any;
    };
    skipValidation: any;
    labels: {
        lang: string;
        text: string;
        role: string;
        id: any;
    }[];
    id: string;
}

const hardCodedData = {
    "defaultValues": [],
    "contextSettings": [
        {
            "context": "1_Consolidated",
            "period": "Period1",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c219"
        },
        {
            "context": "2_Consolidated",
            "period": "Period2",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21a"
        },
        {
            "context": "3_Consolidated",
            "period": "Period3",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21b"
        },
        {
            "context": "4_Consolidated",
            "period": "Period4",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21c"
        },
        {
            "context": "5_Consolidated",
            "period": "Period5",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21d"
        },
        {
            "context": "6_Consolidated",
            "period": "Period6",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21e"
        },
        {
            "context": "7_Consolidated",
            "period": "Period7",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c21f"
        },
        {
            "context": "8_Consolidated",
            "period": "Period8",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                }
            ],
            "id": "619e0d5bae0f62263804c220"
        },
        {
            "context": "1_Parent",
            "period": "Period1",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c221"
        },
        {
            "context": "2_Parent",
            "period": "Period2",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c222"
        },
        {
            "context": "3_Parent",
            "period": "Period3",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c223"
        },
        {
            "context": "4_Parent",
            "period": "Period4",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c224"
        },
        {
            "context": "5_Parent",
            "period": "Period5",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c225"
        },
        {
            "context": "6_Parent",
            "period": "Period6",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c226"
        },
        {
            "context": "7_Parent",
            "period": "Period7",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c227"
        },
        {
            "context": "8_Parent",
            "period": "Period8",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c228"
        },
        {
            "context": "1",
            "period": "Period1",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c229"
        },
        {
            "context": "2",
            "period": "Period2",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22a"
        },
        {
            "context": "3",
            "period": "Period3",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22b"
        },
        {
            "context": "4",
            "period": "Period4",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22c"
        },
        {
            "context": "5",
            "period": "Period5",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22d"
        },
        {
            "context": "6",
            "period": "Period6",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22e"
        },
        {
            "context": "7",
            "period": "Period7",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c22f"
        },
        {
            "context": "8",
            "period": "Period8",
            "taxonomies": [],
            "dimensionValueReferences": [
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedMember",
                            "description": "ConsolidatedMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "ConsolidatedMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:ConsolidatedMember"
                },
                {
                    "taxonomyValue": {
                        "dimensionReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "ConsolidatedAndSeparateFinancialStatementsAxis",
                            "description": "ConsolidatedAndSeparateFinancialStatementsAxis"
                        },
                        "dimensionValueReference": {
                            "namespaceAbbreviation": "ifrs-full",
                            "name": "SeparateMember",
                            "description": "SeparateMember"
                        }
                    },
                    "customDimensionValue": null,
                    "onlyWhenConsolidated": null,
                    "isCustom": false,
                    "dimensionName": "ConsolidatedAndSeparateFinancialStatementsAxis",
                    "valueName": "SeparateMember",
                    "name": "ConsolidatedAndSeparateFinancialStatementsAxis:SeparateMember"
                }
            ],
            "id": "619e0d5bae0f62263804c230"
        }
    ],
    "entryPoints": [
        "619e0329ae0f622638047630",
        "619e03caae0f62263804bdfc",
        "619e0453ae0f62263804be3a",
        "619e04deae0f62263804be78",
        "619e056aae0f62263804beb6",
        "619e05faae0f62263804bef4",
        "619e0684ae0f62263804bf32",
        "619e070eae0f62263804bf70",
        "619e07a1ae0f62263804bfae",
        "619e0834ae0f62263804bfec",
        "619e08c0ae0f62263804c02a",
        "619e094fae0f62263804c068",
        "619e09e5ae0f62263804c0a6",
        "619e0a76ae0f62263804c0e4",
        "619e0b09ae0f62263804c122",
        "619e0b98ae0f62263804c160",
        "619e0c30ae0f62263804c19e",
        "619e0cc6ae0f62263804c1db",
        "619e0d5aae0f62263804c218"
    ],
    "identifierElementId": null,
    "identifierElementName": null,
    "reportTypeElementId": null,
    "reportTypeElementName": null,
    "languages": [
        "ar",
        "da",
        "de",
        "en",
        "es",
        "fr",
        "hi",
        "pt",
        "ru",
        "zh"
    ],
    "taxonomyLanguages": [
        "da",
        "en",
        "hi",
        "ar",
        "pt",
        "zh",
        "ru",
        "es",
        "fr",
        "de"
    ],
    "settings": [
        {
            "taxonomies": [],
            "settingName": "EntryPoint",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Taxonomy entrypoint",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c232"
                },
                {
                    "lang": "da",
                    "text": "Taksonomi entrypoint",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c233"
                }
            ],
            "id": "619e0d5bae0f62263804c231"
        },
        {
            "taxonomies": [],
            "settingName": "ReportType",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Report type",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c235"
                },
                {
                    "lang": "da",
                    "text": "Rapporttype",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c236"
                }
            ],
            "id": "619e0d5bae0f62263804c234"
        },
        {
            "taxonomies": [],
            "settingName": "CurrencyCode",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Currency code",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c238"
                },
                {
                    "lang": "da",
                    "text": "Valutakode",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c239"
                }
            ],
            "id": "619e0d5bae0f62263804c237"
        },
        {
            "taxonomies": [],
            "settingName": "Identifier",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Identification number [CVR] of reporting entity",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c23b"
                },
                {
                    "lang": "da",
                    "text": "Regnskabsaflæggende virksomheds CVR-nr.",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c23c"
                }
            ],
            "id": "619e0d5bae0f62263804c23a"
        },
        {
            "taxonomies": [],
            "settingName": "Language",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Report language code",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c23e"
                },
                {
                    "lang": "da",
                    "text": "Rapport sprogkode",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c23f"
                }
            ],
            "id": "619e0d5bae0f62263804c23d"
        },
        {
            "taxonomies": [],
            "settingName": "StartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 1",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c241"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 1",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c242"
                }
            ],
            "id": "619e0d5bae0f62263804c240"
        },
        {
            "taxonomies": [],
            "settingName": "EndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 1",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c244"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 1",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c245"
                }
            ],
            "id": "619e0d5bae0f62263804c243"
        },
        {
            "taxonomies": [],
            "settingName": "PreviousYearStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 2",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c247"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 2",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c248"
                }
            ],
            "id": "619e0d5bae0f62263804c246"
        },
        {
            "taxonomies": [],
            "settingName": "PreviousYearEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 2",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c24a"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 2",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c24b"
                }
            ],
            "id": "619e0d5bae0f62263804c249"
        },
        {
            "taxonomies": [],
            "settingName": "TwoYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 3",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c24d"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 3",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c24e"
                }
            ],
            "id": "619e0d5bae0f62263804c24c"
        },
        {
            "taxonomies": [],
            "settingName": "TwoYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 3",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c250"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 3",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c251"
                }
            ],
            "id": "619e0d5bae0f62263804c24f"
        },
        {
            "taxonomies": [],
            "settingName": "ThreeYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 4",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c253"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 4",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c254"
                }
            ],
            "id": "619e0d5bae0f62263804c252"
        },
        {
            "taxonomies": [],
            "settingName": "ThreeYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 4",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c256"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 4",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c257"
                }
            ],
            "id": "619e0d5bae0f62263804c255"
        },
        {
            "taxonomies": [],
            "settingName": "FourYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 5",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c259"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 5",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c25a"
                }
            ],
            "id": "619e0d5bae0f62263804c258"
        },
        {
            "taxonomies": [],
            "settingName": "FourYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 5",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c25c"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 5",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c25d"
                }
            ],
            "id": "619e0d5bae0f62263804c25b"
        },
        {
            "taxonomies": [],
            "settingName": "FiveYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 6",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c25f"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 6",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c260"
                }
            ],
            "id": "619e0d5bae0f62263804c25e"
        },
        {
            "taxonomies": [],
            "settingName": "FiveYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 6",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c262"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 6",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c263"
                }
            ],
            "id": "619e0d5bae0f62263804c261"
        },
        {
            "taxonomies": [],
            "settingName": "SixYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 7",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c265"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 7",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c266"
                }
            ],
            "id": "619e0d5bae0f62263804c264"
        },
        {
            "taxonomies": [],
            "settingName": "SixYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 7",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c268"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 7",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c269"
                }
            ],
            "id": "619e0d5bae0f62263804c267"
        },
        {
            "taxonomies": [],
            "settingName": "SevenYearsAgoStartDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Start date of reporting period 8",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c26b"
                },
                {
                    "lang": "da",
                    "text": "Startdato for periode 8",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c26c"
                }
            ],
            "id": "619e0d5bae0f62263804c26a"
        },
        {
            "taxonomies": [],
            "settingName": "SevenYearsAgoEndDate",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "End date of reporting period 8",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c26e"
                },
                {
                    "lang": "da",
                    "text": "Slutdato for periode 8",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c26f"
                }
            ],
            "id": "619e0d5bae0f62263804c26d"
        },
        {
            "taxonomies": [],
            "settingName": "IdentifierScheme",
            "required": false,
            "labels": [
                {
                    "lang": "en",
                    "text": "Identifier scheme",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c271"
                },
                {
                    "lang": "da",
                    "text": "Identifier scheme",
                    "role": "standard",
                    "id": "619e0d5bae0f62263804c272"
                }
            ],
            "id": "619e0d5bae0f62263804c270"
        }
    ],
    "elementsToLog": [],
    "settingsToLog": [],
    "allowEmptyLog": false,
    "dimensionValuesToIgnore": [],
    "importId": null,
    "published": true,
    "forceValidate": false,
    "disclosureSystem": null,
    "namespaces": [
        {
            "abbreviation": "entryAll-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entryAll-ifrs-dk_2021-12-20",
            "id": "619e0329ae0f6226380475bd"
        },
        {
            "abbreviation": "xsd",
            "href": "http://www.w3.org/2001/XMLSchema",
            "id": "619e0329ae0f6226380475be"
        },
        {
            "abbreviation": "xl",
            "href": "http://www.xbrl.org/2003/XLink",
            "id": "619e0329ae0f6226380475bf"
        },
        {
            "abbreviation": "link",
            "href": "http://www.xbrl.org/2003/linkbase",
            "id": "619e0329ae0f6226380475c0"
        },
        {
            "abbreviation": "xsi",
            "href": "http://www.w3.org/2001/XMLSchema-instance",
            "id": "619e0329ae0f6226380475c1"
        },
        {
            "abbreviation": "xbrldt",
            "href": "http://xbrl.org/2005/xbrldt",
            "id": "619e0329ae0f6226380475c2"
        },
        {
            "abbreviation": "xbrli",
            "href": "http://www.xbrl.org/2003/instance",
            "id": "619e0329ae0f6226380475c3"
        },
        {
            "abbreviation": "xlink",
            "href": "http://www.w3.org/1999/xlink",
            "id": "619e0329ae0f6226380475c4"
        },
        {
            "abbreviation": "gen",
            "href": "http://xbrl.org/2008/generic",
            "id": "619e0329ae0f6226380475c5"
        },
        {
            "abbreviation": "label",
            "href": "http://xbrl.org/2008/label",
            "id": "619e0329ae0f6226380475c6"
        },
        {
            "abbreviation": "rol_ifrs-dk_105.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_105.00_2021-12-20",
            "id": "619e0329ae0f6226380475c7"
        },
        {
            "abbreviation": "tch",
            "href": "http://xbrl.dcca.dk/tch",
            "id": "619e0329ae0f6226380475c8"
        },
        {
            "abbreviation": "ref",
            "href": "http://www.xbrl.org/2006/ref",
            "id": "619e0329ae0f6226380475c9"
        },
        {
            "abbreviation": "nonnum",
            "href": "http://www.xbrl.org/dtr/type/non-numeric",
            "id": "619e0329ae0f6226380475ca"
        },
        {
            "abbreviation": "entry",
            "href": "http://entry",
            "id": "619e0329ae0f6226380475cb"
        },
        {
            "abbreviation": "net",
            "href": "http://www.xbrl.org/2009/role/net",
            "id": "619e0329ae0f6226380475cc"
        },
        {
            "abbreviation": "num",
            "href": "http://www.xbrl.org/dtr/type/numeric",
            "id": "619e0329ae0f6226380475cd"
        },
        {
            "abbreviation": "reference",
            "href": "http://xbrl.org/2008/reference",
            "id": "619e0329ae0f6226380475ce"
        },
        {
            "abbreviation": "negated",
            "href": "http://www.xbrl.org/2009/role/negated",
            "id": "619e0329ae0f6226380475cf"
        },
        {
            "abbreviation": "cmn",
            "href": "http://xbrl.dcca.dk/cmn",
            "id": "619e0329ae0f6226380475d0"
        },
        {
            "abbreviation": "rol_ifrs-dk_510.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_510.00_2021-12-20",
            "id": "619e0329ae0f6226380475d1"
        },
        {
            "abbreviation": "rol_ifrs-dk_520.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_520.00_2021-12-20",
            "id": "619e0329ae0f6226380475d2"
        },
        {
            "abbreviation": "rol_ifrs-dk_110.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_110.00_2021-12-20",
            "id": "619e0329ae0f6226380475d3"
        },
        {
            "abbreviation": "rol_ifrs-dk_210.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_210.00_2021-12-20",
            "id": "619e0329ae0f6226380475d4"
        },
        {
            "abbreviation": "ifrs-dk-sft",
            "href": "http://xbrl.dcca.dk/StatementOfFinancialPosition",
            "id": "619e0329ae0f6226380475d5"
        },
        {
            "abbreviation": "ifrs-dk-entry-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry-ifrs-dk_2021-12-20",
            "id": "619e0329ae0f6226380475d6"
        },
        {
            "abbreviation": "rol_ifrs-dk_220.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_220.00_2021-12-20",
            "id": "619e0329ae0f6226380475d7"
        },
        {
            "abbreviation": "ifrs-dk-is",
            "href": "http://xbrl.dcca.dk/IncomeStatement",
            "id": "619e0329ae0f6226380475d8"
        },
        {
            "abbreviation": "rol_ifrs-dk_310.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_310.00_2021-12-20",
            "id": "619e0329ae0f6226380475d9"
        },
        {
            "abbreviation": "rol_ifrs-dk_320.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_320.00_2021-12-20",
            "id": "619e0329ae0f6226380475da"
        },
        {
            "abbreviation": "rol_ifrs-dk_330.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_330.00_2021-12-20",
            "id": "619e0329ae0f6226380475db"
        },
        {
            "abbreviation": "rol_ifrs-dk_410.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_410.00_2021-12-20",
            "id": "619e0329ae0f6226380475dc"
        },
        {
            "abbreviation": "rol_ifrs-dk_420.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_420.00_2021-12-20",
            "id": "619e0329ae0f6226380475dd"
        },
        {
            "abbreviation": "rol_ifrs-dk_610.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_610.00_2021-12-20",
            "id": "619e0329ae0f6226380475de"
        },
        {
            "abbreviation": "rol_ifrs-dk_710.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_710.00_2021-12-20",
            "id": "619e0329ae0f6226380475df"
        },
        {
            "abbreviation": "rol_ifrs-dk_8NN.NN_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/ifrs-dk/rol_ifrs-dk_8NN.NN_2021-12-20",
            "id": "619e0329ae0f6226380475e0"
        },
        {
            "abbreviation": "ifrs-full",
            "href": "http://xbrl.ifrs.org/taxonomy/2014-03-05/ifrs-full",
            "id": "619e0329ae0f6226380475e1"
        },
        {
            "abbreviation": "rol_dim",
            "href": "http://xbrl.ifrs.org/role/ifrs/dimensions",
            "id": "619e0329ae0f6226380475e2"
        },
        {
            "abbreviation": "ifrs-dk",
            "href": "http://xbrl.dcca.dk/ifrs-dk-cor_2021-12-20",
            "id": "619e0329ae0f6226380475e3"
        },
        {
            "abbreviation": "rol_ifrs_12_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_12_2014-03-05",
            "id": "619e0329ae0f6226380475e4"
        },
        {
            "abbreviation": "rol_ias_1_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_1_2014-03-05",
            "id": "619e0329ae0f6226380475e5"
        },
        {
            "abbreviation": "rol_ias_27_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_27_2014-03-05",
            "id": "619e0329ae0f6226380475e6"
        },
        {
            "abbreviation": "rol_ifric_2_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifric_2_2014-03-05",
            "id": "619e0329ae0f6226380475e7"
        },
        {
            "abbreviation": "rol_ias_11_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_11_2014-03-05",
            "id": "619e0329ae0f6226380475e8"
        },
        {
            "abbreviation": "rol_ias_29_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_29_2014-03-05",
            "id": "619e0329ae0f6226380475e9"
        },
        {
            "abbreviation": "rol_ias_34_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_34_2014-03-05",
            "id": "619e0329ae0f6226380475ea"
        },
        {
            "abbreviation": "rol_ifrs_1_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_1_2014-03-05",
            "id": "619e0329ae0f6226380475eb"
        },
        {
            "abbreviation": "rol_ias_36_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_36_2014-03-05",
            "id": "619e0329ae0f6226380475ec"
        },
        {
            "abbreviation": "rol_ifrs_3_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_3_2014-03-05",
            "id": "619e0329ae0f6226380475ed"
        },
        {
            "abbreviation": "rol_ias_41_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_41_2014-03-05",
            "id": "619e0329ae0f6226380475ee"
        },
        {
            "abbreviation": "rol_ias_7_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_7_2014-03-05",
            "id": "619e0329ae0f6226380475ef"
        },
        {
            "abbreviation": "rol_ias_20_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_20_2014-03-05",
            "id": "619e0329ae0f6226380475f0"
        },
        {
            "abbreviation": "rol_ias_38_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_38_2014-03-05",
            "id": "619e0329ae0f6226380475f1"
        },
        {
            "abbreviation": "rol_ifrs_5_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_5_2014-03-05",
            "id": "619e0329ae0f6226380475f2"
        },
        {
            "abbreviation": "rol_ias_1_2011-06-01",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_1_2011-06-01",
            "id": "619e0329ae0f6226380475f3"
        },
        {
            "abbreviation": "rol_ias_17_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_17_2014-03-05",
            "id": "619e0329ae0f6226380475f4"
        },
        {
            "abbreviation": "rol_ifrs_7_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_7_2014-03-05",
            "id": "619e0329ae0f6226380475f5"
        },
        {
            "abbreviation": "rol_ias_19_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_19_2014-03-05",
            "id": "619e0329ae0f6226380475f6"
        },
        {
            "abbreviation": "rol_ias_24_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_24_2014-03-05",
            "id": "619e0329ae0f6226380475f7"
        },
        {
            "abbreviation": "rol_ias_26_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_26_2014-03-05",
            "id": "619e0329ae0f6226380475f8"
        },
        {
            "abbreviation": "rol_ias_7_2011-06-01",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_7_2011-06-01",
            "id": "619e0329ae0f6226380475f9"
        },
        {
            "abbreviation": "rol_ifrs_13_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_13_2014-03-05",
            "id": "619e0329ae0f6226380475fa"
        },
        {
            "abbreviation": "rol_ias_2_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_2_2014-03-05",
            "id": "619e0329ae0f6226380475fb"
        },
        {
            "abbreviation": "rol_ias_10_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_10_2014-03-05",
            "id": "619e0329ae0f6226380475fc"
        },
        {
            "abbreviation": "rol_ias_33_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_33_2014-03-05",
            "id": "619e0329ae0f6226380475fd"
        },
        {
            "abbreviation": "rol_ifric_5_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifric_5_2014-03-05",
            "id": "619e0329ae0f6226380475fe"
        },
        {
            "abbreviation": "rol_ias_12_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_12_2014-03-05",
            "id": "619e0329ae0f6226380475ff"
        },
        {
            "abbreviation": "rol_ias_40_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_40_2014-03-05",
            "id": "619e0329ae0f622638047600"
        },
        {
            "abbreviation": "rol_ifrs_2_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_2_2014-03-05",
            "id": "619e0329ae0f622638047601"
        },
        {
            "abbreviation": "rol_ias_37_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_37_2014-03-05",
            "id": "619e0329ae0f622638047602"
        },
        {
            "abbreviation": "rol_ifrs_4_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_4_2014-03-05",
            "id": "619e0329ae0f622638047603"
        },
        {
            "abbreviation": "ifrs-temp-entry",
            "href": "http://ifrs-temp-entry",
            "id": "619e0329ae0f622638047604"
        },
        {
            "abbreviation": "rol_ias_16_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_16_2014-03-05",
            "id": "619e0329ae0f622638047605"
        },
        {
            "abbreviation": "rol_ias_8_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_8_2014-03-05",
            "id": "619e0329ae0f622638047606"
        },
        {
            "abbreviation": "rol_sic_27_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_sic_27_2014-03-05",
            "id": "619e0329ae0f622638047607"
        },
        {
            "abbreviation": "rol_ifrs_6_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_6_2014-03-05",
            "id": "619e0329ae0f622638047608"
        },
        {
            "abbreviation": "rol_ias_21_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_21_2014-03-05",
            "id": "619e0329ae0f622638047609"
        },
        {
            "abbreviation": "rol_ias_18_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_18_2014-03-05",
            "id": "619e0329ae0f62263804760a"
        },
        {
            "abbreviation": "rol_ias_23_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ias_23_2014-03-05",
            "id": "619e0329ae0f62263804760b"
        },
        {
            "abbreviation": "rol_sic_29_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_sic_29_2014-03-05",
            "id": "619e0329ae0f62263804760c"
        },
        {
            "abbreviation": "rol_ifrs_8_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ifrs_8_2014-03-05",
            "id": "619e0329ae0f62263804760d"
        },
        {
            "abbreviation": "rol_ps_mc_2014-03-05",
            "href": "http://xbrl.ifrs.org/role/ifrs/rol_ps_mc_2014-03-05",
            "id": "619e0329ae0f62263804760e"
        },
        {
            "abbreviation": "ifrs-mc",
            "href": "http://xbrl.ifrs.org/taxonomy/2014-03-05/ifrs-mc",
            "id": "619e0329ae0f62263804760f"
        },
        {
            "abbreviation": "info",
            "href": "http://xbrl.iasb.org/info",
            "id": "619e0329ae0f622638047610"
        },
        {
            "abbreviation": "entry-sob",
            "href": "http://xbrl.dcca.dk/entry-sob",
            "id": "619e0329ae0f622638047611"
        },
        {
            "abbreviation": "sob",
            "href": "http://xbrl.dcca.dk/sob",
            "id": "619e0329ae0f622638047612"
        },
        {
            "abbreviation": "rol_sob_300.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/sob/rol_sob_300.00_2021-12-20",
            "id": "619e0329ae0f622638047613"
        },
        {
            "abbreviation": "cmn_rol",
            "href": "http://archprod.service.eogs.dk/taxonomy/20211220/cmn_rol.xsd",
            "id": "619e0329ae0f622638047614"
        },
        {
            "abbreviation": "rol_mrv_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/mrv/rol_mrv_2021-12-20",
            "id": "619e0329ae0f622638047615"
        },
        {
            "abbreviation": "tax",
            "href": "http://xbrl.dcca.dk/tax",
            "id": "619e0329ae0f622638047616"
        },
        {
            "abbreviation": "reference",
            "href": "http://www.xbrl.org/2009/role/reference",
            "id": "619e0329ae0f622638047617"
        },
        {
            "abbreviation": "gsd",
            "href": "http://xbrl.dcca.dk/gsd",
            "id": "619e0329ae0f622638047618"
        },
        {
            "abbreviation": "dst",
            "href": "http://xbrl.dcca.dk/dst",
            "id": "619e0329ae0f622638047619"
        },
        {
            "abbreviation": "fsa",
            "href": "http://xbrl.dcca.dk/fsa",
            "id": "619e0329ae0f62263804761a"
        },
        {
            "abbreviation": "mrv",
            "href": "http://xbrl.dcca.dk/mrv",
            "id": "619e0329ae0f62263804761b"
        },
        {
            "abbreviation": "esg",
            "href": "http://xbrl.dcca.dk/esg",
            "id": "619e0329ae0f62263804761c"
        },
        {
            "abbreviation": "tar",
            "href": "http://xbrl.dcca.dk/tar",
            "id": "619e0329ae0f62263804761d"
        },
        {
            "abbreviation": "entry-mrv",
            "href": "http://xbrl.dcca.dk/entry-mrv",
            "id": "619e0329ae0f62263804761e"
        },
        {
            "abbreviation": "mrvcsr",
            "href": "http://xbrl.dcca.dk/mrvcsr",
            "id": "619e0329ae0f62263804761f"
        },
        {
            "abbreviation": "entry-gsd",
            "href": "http://xbrl.dcca.dk/entry-gsd",
            "id": "619e0329ae0f622638047620"
        },
        {
            "abbreviation": "ca",
            "href": "http://xbrl.org/2008/assertion/consistency",
            "id": "619e0329ae0f622638047621"
        },
        {
            "abbreviation": "fn",
            "href": "http://www.w3.org/2005/xpath-functions",
            "id": "619e0329ae0f622638047622"
        },
        {
            "abbreviation": "variable",
            "href": "http://xbrl.org/2008/variable",
            "id": "619e0329ae0f622638047623"
        },
        {
            "abbreviation": "xfi",
            "href": "http://www.xbrl.org/2008/function/instance",
            "id": "619e0329ae0f622638047624"
        },
        {
            "abbreviation": "cf",
            "href": "http://xbrl.org/2008/filter/concept",
            "id": "619e0329ae0f622638047625"
        },
        {
            "abbreviation": "valm",
            "href": "http://xbrl.org/2010/message/validation",
            "id": "619e0329ae0f622638047626"
        },
        {
            "abbreviation": "ea",
            "href": "http://xbrl.org/2008/assertion/existence",
            "id": "619e0329ae0f622638047627"
        },
        {
            "abbreviation": "validation",
            "href": "http://xbrl.org/2008/validation",
            "id": "619e0329ae0f622638047628"
        },
        {
            "abbreviation": "formula",
            "href": "http://xbrl.org/2008/formula",
            "id": "619e0329ae0f622638047629"
        },
        {
            "abbreviation": "msg",
            "href": "http://xbrl.org/2010/message",
            "id": "619e0329ae0f62263804762a"
        },
        {
            "abbreviation": "va",
            "href": "http://xbrl.org/2008/assertion/value",
            "id": "619e0329ae0f62263804762b"
        },
        {
            "abbreviation": "rol_gsd_000.00_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/gsd/rol_gsd_000.00_2021-12-20",
            "id": "619e0329ae0f62263804762c"
        },
        {
            "abbreviation": "entry-arr",
            "href": "http://xbrl.dcca.dk/entry-arr",
            "id": "619e0329ae0f62263804762d"
        },
        {
            "abbreviation": "arr",
            "href": "http://xbrl.dcca.dk/arr",
            "id": "619e0329ae0f62263804762e"
        },
        {
            "abbreviation": "rol_arr_1NN.NN_2021-12-20",
            "href": "http://xbrl.dcca.dk/role/arr/rol_arr_1NN.NN_2021-12-20",
            "id": "619e0329ae0f62263804762f"
        },
        {
            "abbreviation": "entry01-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry01-ifrs-dk_2021-12-20",
            "id": "619e03caae0f62263804bdfb"
        },
        {
            "abbreviation": "entry02-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry02-ifrs-dk_2021-12-20",
            "id": "619e0453ae0f62263804be39"
        },
        {
            "abbreviation": "entry03-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry03-ifrs-dk_2021-12-20",
            "id": "619e04deae0f62263804be77"
        },
        {
            "abbreviation": "entry04-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry04-ifrs-dk_2021-12-20",
            "id": "619e056aae0f62263804beb5"
        },
        {
            "abbreviation": "entry05-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry05-ifrs-dk_2021-12-20",
            "id": "619e05faae0f62263804bef3"
        },
        {
            "abbreviation": "entry06-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry06-ifrs-dk_2021-12-20",
            "id": "619e0684ae0f62263804bf31"
        },
        {
            "abbreviation": "entry07-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry07-ifrs-dk_2021-12-20",
            "id": "619e070eae0f62263804bf6f"
        },
        {
            "abbreviation": "entry08-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry08-ifrs-dk_2021-12-20",
            "id": "619e07a1ae0f62263804bfad"
        },
        {
            "abbreviation": "entry09-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry09-ifrs-dk_2021-12-20",
            "id": "619e0834ae0f62263804bfeb"
        },
        {
            "abbreviation": "entry10-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry10-ifrs-dk_2021-12-20",
            "id": "619e08c0ae0f62263804c029"
        },
        {
            "abbreviation": "entry11-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry11-ifrs-dk_2021-12-20",
            "id": "619e094fae0f62263804c067"
        },
        {
            "abbreviation": "entry12-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry12-ifrs-dk_2021-12-20",
            "id": "619e09e5ae0f62263804c0a5"
        },
        {
            "abbreviation": "entry13-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry13-ifrs-dk_2021-12-20",
            "id": "619e0a76ae0f62263804c0e3"
        },
        {
            "abbreviation": "entry14-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry14-ifrs-dk_2021-12-20",
            "id": "619e0b09ae0f62263804c121"
        },
        {
            "abbreviation": "entry15-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry15-ifrs-dk_2021-12-20",
            "id": "619e0b98ae0f62263804c15f"
        },
        {
            "abbreviation": "entry16-ifrs-dk_2021-12-20",
            "href": "http://xbrl.dcca.dk/ifrs-dk/entry16-ifrs-dk_2021-12-20",
            "id": "619e0c30ae0f62263804c19d"
        }
    ],
    "dimensions": [],
    "dimensionValues": [],
    "visible": true,
    "rewrites": [
        {
            "uri": "http://ifrs/",
            "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/"
        },
        {
            "uri": "http://mrv/",
            "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/"
        },
        {
            "uri": "http://arr/",
            "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/"
        },
        {
            "uri": "http://gsd/",
            "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/"
        },
        {
            "uri": "http://sob/",
            "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/"
        }
    ],
    "baseUri": "http://archprod.service.eogs.dk/taxonomy/",
    "useRealTableNamespace": false,
    "strictParsing": false,
    "overrideCurrencyDimensionValue": null,
    "overrideCurrencyDimension": null,
    "standalone": false,
    "hasExternalValidation": false,
    "validationRuleset": null,
    "isMappingTaxonomy": false,
    "mappingTaxonomyId": null,
    "isReferenceTaxonomy": false,
    "hypercubeForLineItemsWithoutDimensions": null,
    "defaultDimensionRole": null,
    "taxonomyExtensionBuilder": null,
    "isAnnualTaxonomy": true,
    "taxonomyPackagePath": null,
    "additionalEntrypoints": null,
    "validator": null,
    "extensionNamingConventions": {
        "entrypointFile": "{customerdomain}_entry_{date}.xsd",
        "labelFile": "{customerdomain}_lab.xml",
        "calculationsFile": "{customerdomain}_cal.xml",
        "presentationFile": "{customerdomain}_pre.xml",
        "definitionFile": "{customerdomain}_def.xml",
        "documentationFile": "{customerdomain}_doc.xml",
        "referenceFile": null
    },
    "skipValidation": null,
    "labels": [
        {
            "lang": "da",
            "text": "DK IFRS 20211220",
            "role": "standard",
            "id": null
        },
        {
            "lang": "en",
            "text": "DK IFRS 20211220",
            "role": "standard",
            "id": null
        },
        {
            "lang": "da",
            "text": "DK IFRS",
            "role": "standard",
            "id": null
        },
        {
            "lang": "en",
            "text": "DK IFRS",
            "role": "standard",
            "id": null
        }
    ],
    "id": "619e0329ae0f6226380475bc"
}