import { Container } from "@mui/system";
import { Paper } from "@mui/material";
import { ContainerMaxWidth } from "../../helpers/constants";

export const ObjectsWrapper = ({ children }: any) => {
  return (
    <Container
      maxWidth={ContainerMaxWidth}
      sx={{
        maxWidth: 2100,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        pt: 5,
      }}
    >
      <Paper>{children}</Paper>
    </Container>
  );
};
