import React, { useEffect, useState } from 'react';
import { ColumnProps, UsageLogPayload, UsageLogResponse, UsageLogResult } from '../../api/types';
import { t } from '@lingui/macro';
import { Loader } from '../loader';
import DataTable from '../dataTable';
import { Container } from '@mui/material';
import { getUsageLogs } from '../../api/customObjects';

export default function UsageComponent({
    id,
    activeTab,
}: {
    id: string | undefined;
    activeTab: string;
}) {
    const [usageLogs, setUsageLogs] = useState<UsageLogResponse | null>(null);

    useEffect(() => {
        if (!id) return;
        const fetchUsageLogs = async () => {
            const payload: UsageLogPayload = {
                page: 1,
                pageCount: 10,
                filters: [],
                taxonomyIds: [],
            };
            const response = await getUsageLogs(id, activeTab, payload);
            setUsageLogs(response);
        };

        fetchUsageLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {usageLogs ? (
                <DataTable<UsageLogResult>
                    columns={columns}
                    data={usageLogs.result}
                />
            ) : (
                <Loader height={75} />
            )}
        </Container>
    );
};


const columns: ColumnProps[] = [
    {
        header: {
            id: "entity",
            label: t`Entity`,
        },
        key: "entity",
        width: 200,
    },
    {
        header: {
            id: "timestamp",
            label: t`Timestamp`,
        },
        key: "timestamp",
        width: 200,
    },
];
