import { t } from "@lingui/macro";
import { Container, Table, TableBody, TableContainer } from "@mui/material";
import DataTableRow from "./dataTableRow";
import EmptyTableAlert from "./emptyTableAlert";
import TableHeader, { TableColumnHeader } from "./tableHeader";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Apifilters } from "../api/types";

export interface DataTableColumn<T> {
  header: TableColumnHeader<T>;
  key: keyof T | string;
  valueFormatter?: (row: T) => string | JSX.Element;
  width?: number;
  shouldWrapVertically?: boolean;
}

export interface DataTableProps<T extends { id: string }> {
  data: T[];
  columns: DataTableColumn<T>[];
  onClickRow?: (rowid: string) => void;
  expandedRowComponent?: React.ComponentType<{
    rowData: T;
    columnCount: number;
    isOpen: boolean;
  }>;
  expandOnRowClick?: boolean;
  nested?: boolean;
  minHeight?: number;
  maxHeight?: number;
  noWidth?: boolean;
  filters?: Apifilters[];
  setFilter?: (id: string, value?: string | boolean, sortDecending?: boolean) => void;
  noDataMessage?: string;
  renderTableHeader?: boolean;
  loading?: boolean;
  verticalAlign?: "top" | "bottom" | "middle";
}

export default function DataTable<T extends { id: string }>({
  data,
  columns,
  onClickRow,
  expandedRowComponent,
  expandOnRowClick,
  nested,
  minHeight,
  maxHeight,
  noWidth,
  setFilter,
  filters,
  renderTableHeader = true,
  loading,
  verticalAlign,
  noDataMessage = t`Consider removing some existing filters or adding some`,
}: DataTableProps<T>) {
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: 150,
        backgroundColor: "transparent",
        pr: noWidth ? "0!important" : undefined,
        pl: noWidth ? "0!important" : undefined,
      }}
    >
      <TableContainer
        sx={{
          pb: 5,
          maxHeight: maxHeight,
          minHeight: minHeight,
        }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: nested ? undefined : 750,
            margin: "0 auto",
          }}
          aria-labelledby="tableTitle"
          size={nested ? "small" : "medium"}
        >
          {renderTableHeader &&
            <TableHeader
              column={columns[0]}
              setFilter={setFilter}
              filters={filters || []}
              columnHeaders={columns.map((column) => column.header)}
              expandable={expandedRowComponent !== undefined}
              nested={nested}
            />
          }
          <TableBody>
            {data.map((row, index) => {
              return (
                <DataTableRow<T>
                  key={`data_table_row_${row.id}`}
                  index={index}
                  columns={columns}
                  onClickRow={onClickRow}
                  rowData={row}
                  expandOnRowClick={expandOnRowClick}
                  expandedRowComponent={expandedRowComponent}
                  nested={nested}
                  verticalAlign={verticalAlign}
                />
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <EmptyTableAlert
            severity="info"
            title={t`No data was found`}
            subTitle={noDataMessage}
            mainIcon={
              <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
            }
            showIcon={true}
            loading={loading}
          />
        )}
      </TableContainer>
    </Container>
  );
}
