import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { t } from "@lingui/macro";
import axios from "axios";

interface Label {
    lang: string;
    text?: string;
    role?: string;
    label?: string;
}

interface LabelTableProps {
    initialLabels: Label[];
    onLabelsChange: (labels: Label[]) => void;
    fieldType: "default" | "extensionElement" | "documentation";
}

export default function LabelTable({ initialLabels, onLabelsChange, fieldType }: LabelTableProps) {
    const [labels, setLabels] = useState<Label[]>(initialLabels);
    const [languages, setLanguages] = useState<string[]>([]);

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await axios.get("/test"); //TODO setup real api call - api/taxonomies/languages?taxonomyIds=5e1c9bfe82ac091b20eff353&taxonomyIds=615cb14e8309c47b18199cb2
                setLanguages(response.data);
            } catch (error) {
                console.error("Error fetching languages:", error);
            }
        };

        fetchLanguages();
    }, []);

    const handleLabelChange = (index: number, field: keyof Label, value: string) => {
        const updatedLabels = [...labels];
        updatedLabels[index] = {
            ...updatedLabels[index],
            [field]: value,
        };
        setLabels(updatedLabels);
        onLabelsChange(updatedLabels);
    };

    const handleAddRow = () => {
        if (fieldType === "extensionElement") {
            setLabels([...labels, { lang: "", label: "", role: "" }]);
        } else if (fieldType === "documentation") {
            setLabels([...labels, { lang: "", label: "" }]);
        } else {
            setLabels([...labels, { lang: "", text: "" }]);
        }
    };

    const handleDeleteRow = (index: number) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
        onLabelsChange(updatedLabels);
    };

    return (
        <Box>
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ height: '32px' }}>
                            <TableCell sx={{ padding: "4px" }}>{t`Language`}</TableCell>
                            <TableCell sx={{ padding: "4px" }}>{t`Label`}</TableCell>
                            {fieldType === "extensionElement" && (
                                <TableCell sx={{ padding: "4px" }}>{t`Role`}</TableCell>
                            )}
                            <TableCell align="right" sx={{ padding: "4px" }}>
                                <IconButton onClick={handleAddRow} size="small">
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {labels.map((label, index) => (
                            <TableRow key={index} sx={{ height: '32px' }}>
                                <TableCell>
                                    <Select
                                        value={label.lang}
                                        onChange={(e) => handleLabelChange(index, "lang", e.target.value)}
                                        displayEmpty
                                        fullWidth
                                        size="small"
                                        sx={{
                                            "& .MuiSelect-select": {
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                color: "grey"
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>{t`Select Language`}</em>
                                        </MenuItem>
                                        {languages.map((lang) => (
                                            <MenuItem key={lang} value={lang} sx={{ fontSize: "10px", padding: "2px 8px" }}>
                                                {lang.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell sx={{ ml: 4 }}>
                                    <TextField
                                        value={label.text || ""}
                                        onChange={(e) => handleLabelChange(index, "text", e.target.value)}  // Update "text" here
                                        placeholder={fieldType === "documentation" ? "Label" : "text"}
                                        fullWidth
                                        size="small"
                                        sx={{ "& .MuiInputBase-input": { fontSize: "12px", padding: "6px", height: "32px" } }}
                                    />


                                </TableCell>
                                {fieldType === "extensionElement" && (
                                    <TableCell sx={{ padding: "4px" }}>
                                        <Select
                                            value={label.role || ""}
                                            onChange={(e) => handleLabelChange(index, "role", e.target.value)}
                                            displayEmpty
                                            fullWidth
                                            size="small"
                                            sx={{
                                                "& .MuiSelect-select": {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "12px",
                                                    color: "grey"
                                                },
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>{t`Select Role`}</em>
                                            </MenuItem>
                                            <MenuItem value="admin">{t`Admin`}</MenuItem>
                                            <MenuItem value="user">{t`User`}</MenuItem>
                                            <MenuItem value="guest">{t`Guest`}</MenuItem>
                                        </Select>
                                    </TableCell>
                                )}
                                <TableCell align="right" sx={{ padding: "4px" }}>
                                    <IconButton onClick={() => handleDeleteRow(index)} size="small">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
