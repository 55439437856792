import axios, { AxiosResponse } from "axios";
import {
  ApiDetailedTaxonomy,
  ApiSchemes,
  ApiTaxonomy,
  TaxonomyListProps,
} from "./types";

export const updateTaxonomy = async (
  id: string,
  payload: Pick<
    ApiDetailedTaxonomy,
    "systemName" | "type" | "validatingAuthority"
  > & {
    permissionScheme: string | null;
  }
): Promise<AxiosResponse<ApiTaxonomy>> => {
  const { permissionScheme, ...rest } = payload;
  const dataToSend = { ...rest, permissionSchemeId: permissionScheme };

  return axios.put(`/api/taxonomies/edit/${id}`, dataToSend, {
    headers: { "Content-Type": "application/json" },
  });
};

export const importTaxonomyPackageByType = async (
  systemName: string,
  type: "Default" | "EuroFilling",
  file: File,
  abortSignal: AbortSignal | undefined,
  permissionSchemeId: string,
  validatingAuthority = "",
  updateEmail?: string,
  logLevel?: string
): Promise<AxiosResponse<string[]>> => {
  const formData = new FormData();
  formData.append("taxonomyFile", file);
  formData.append("permissionSchemeId", permissionSchemeId);

  return axios.post("/api/taxonomies/import", formData, {
    signal: abortSignal,
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      systemName: systemName,
      type: type,
      validatingAuthority: validatingAuthority,
      emailForUpdates: updateEmail || "",
      updateLevel: logLevel || "",
    },
  });
};

export const importTaxonomyFileByLocation = async (
  file: File,
  fileLocation: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<string[]>> => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/api/taxonomies/schema/import/?fileLocation=${encodeURI(fileLocation)}`,
    formData,
    {
      signal: abortSignal,
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const importTaxonomyFilesByLocation = async (
  files: File[],
  fileLocation: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<string[]>> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files[]", file);
  });
  return axios.post(
    `/api/taxonomies/schema/import/files?filesLocation=${encodeURI(
      fileLocation
    )}`,
    formData,
    {
      signal: abortSignal,
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const getTaxonomySchemes = async (
  cb: string
): Promise<AxiosResponse<ApiSchemes[]>> => {
  return axios.get(`api/taxonomies/permissionSchemes/all?cb=${cb}`);
};

export const getActiveTaxonomySchemes = async (
  cb: string
): Promise<AxiosResponse<ApiSchemes[]>> => {
  return axios.get(`/api/taxonomies/permissionSchemes/active?cb=${cb}`);
};

export const createPermissionScheme = async (
  name: string
): Promise<AxiosResponse<void>> => {
  return axios.post(`api/taxonomies/permissionSchemes/create`, { Name: name });
};

export const getCustomObjectTaxonomies = async () => {
  return axios.get<TaxonomyListProps[]>("api/taxonomies/configurations");
}