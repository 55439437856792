import { ListItemText, FormControlLabel, Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import Grid from '@mui/material/Grid2';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const CombinedCheckBoxLabel = ({
    label,
    value,
    onValueChange,
    alternative = false
}: {
    label?: string,
    value?: boolean | null,
    onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    alternative?: boolean
}) => {
    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={8}>
                <FormControlLabel
                    sx={{
                        p: 1,
                        height: 10,
                    }}
                    control={
                        <Checkbox
                            checked={alternative ? value === true : Boolean(value)}
                            indeterminate={alternative ? value === null : undefined}
                            icon={alternative ? <CheckBoxOutlineBlankIcon /> : undefined}
                            indeterminateIcon={alternative ? <IndeterminateCheckBoxIcon /> : undefined}
                            checkedIcon={alternative ? <CheckBoxIcon /> : undefined}
                            onChange={(event) => {
                                if (alternative) {
                                    let newValue: boolean | null = null;
                                    if (value === null) {
                                        newValue = true;
                                    } else if (value === true) {
                                        newValue = false;
                                    } else if (value === false) {
                                        newValue = null;
                                    }
                                    if (onValueChange) {
                                        const newEvent = {
                                            ...event,
                                            target: {
                                                ...event.target,
                                                value: newValue,
                                            },
                                        } as unknown as ChangeEvent<HTMLInputElement>;

                                        onValueChange(newEvent);
                                    }
                                } else {
                                    if (onValueChange) {
                                        onValueChange(event as ChangeEvent<HTMLInputElement>);
                                    }
                                }
                            }}
                        />
                    } label={undefined}
                />
            </Grid>
        </Grid>
    );
}