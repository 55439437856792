import { ListItemText, Autocomplete, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { cCompanyGroup } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import Grid from '@mui/material/Grid2';

export const CompanyLabel = ({
    label,
    value,
    onValueChange,
    disabled,
    placeholder,
    setSelectedValue,
    selectedValue,
}: {
    label?: string | undefined,
    value?: string,
    onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    placeholder?: string,
    setSelectedValue?: (value: cCompanyGroup | null) => void,
    selectedValue: cCompanyGroup | null,
}) => {
    const [query, setQuery] = useState<string>("");
    const [optionsState, setOptionsState] = useState<cCompanyGroup[]>([]);
    const { data, error, loading } = useGetQuery(
        `api/organizations/search?keyword=${query}`,
        query.length < 3
    );

    useEffect(() => {
        if (!selectedValue) {
            if (typeof value === 'object' && value !== null && 'name' in value) {
                if (setSelectedValue) {
                    setSelectedValue(value);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data) {
            const sortedData = (data as cCompanyGroup[]).sort((a, b) => a.name.localeCompare(b.name));
            setOptionsState(sortedData);
        }
    }, [data]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={8}>
                <Autocomplete
                    options={optionsState}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                    )}
                    loading={loading}
                    noOptionsText={error ? "Error loading options" : "No options"}
                    value={
                        selectedValue !== null ? selectedValue as cCompanyGroup :
                            optionsState.find((option) => option.name === value || (value as any)?.company?.name) || null
                    }
                    onChange={(event, newValue) => {
                        if (setSelectedValue) {
                            setSelectedValue(newValue);
                        }
                        if (onValueChange) {
                            const newEvent = {
                                ...event,
                                target: {
                                    ...event.target,
                                    value: newValue ? newValue.id : "",
                                },
                            } as ChangeEvent<HTMLInputElement>;
                            onValueChange(newEvent);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}