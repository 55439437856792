import { ListItemText, Autocomplete, TextField, Popper } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";
import { DimensionValue } from "../../api/types";
import AlertSnackbar from "../alertSnackbar";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { usePostQuery } from "../../hooks/usePostQuery";
import { DimensionDetailedView } from "./dimensionDetailedView";

export const DimensionLabel = ({
    label,
    selectedValue,
    setSelectedValue,
    value,
    onValueChange,
    preDefinedOptions,
    placeholder,
    disabled
}: {
    label: string | undefined;
    preDefinedOptions?: DimensionValue[] | null;
    selectedValue: DimensionValue | null;
    setSelectedValue: (value: DimensionValue | null) => void;
    value?: DimensionValue;
    onValueChange?: (event: DimensionValue) => void;
    placeholder?: string;
    disabled?: boolean;
}) => {

    const [query, setQuery] = useState("");
    const [optionsState, setOptionsState] = useState<DimensionValue[]>(preDefinedOptions ?? []);
    const [cacheBuster] = useState(crypto.randomUUID());
    const [abortSignal, setAbortSignal] = useState(true);
    const [req, setReq] = useState({
        searchString: query,
        pageCount: 20,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    useEffect(() => {
        if (query.length > 1) {
            setReq({
                searchString: query,
                pageCount: 20,
                taxonomyIds: getSelectedTaxonomyIds(),
            });
            setAbortSignal(false);
        }
    }, [query]);

    const { data, loading, error } = usePostQuery<DimensionValue[]>(
        `/api/custom-objects/dimension-value-references/search?cb=${cacheBuster}`,
        req,
        abortSignal
    );
    useEffect(() => {
        if (data) {
            setOptionsState(data as DimensionValue[]);
        }
    }, [data]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            {error &&
                <AlertSnackbar
                    alertMessage={{ message: error.message, severity: "error" }}
                    updateAlertStatus={() => { }}
                />
            }
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={8}>
                <Autocomplete
                    forcePopupIcon={false}
                    options={optionsState}
                    getOptionLabel={(option) => option?.name || ""}
                    renderOption={(props, option) => (
                        <li {...props} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            border: "1px solid #e6e6e6",
                            justifyContent: "flex-start",
                        }}>
                            {option && <DimensionDetailedView element={option} />}
                        </li>
                    )}
                    loading={loading}
                    value={selectedValue || null}
                    onChange={(_, newValue) => {
                        onValueChange && onValueChange(newValue as DimensionValue);
                        setSelectedValue(newValue as DimensionValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                    )}
                    PopperComponent={(props) => (
                        <Popper {...props} />
                    )}
                />
                {selectedValue && <DimensionDetailedView element={selectedValue} />}
            </Grid>
        </Grid>
    );
};
