import { t } from "@lingui/macro";
import { ColumnProps, dataMatchingToNavBar } from "../../../api/types";
import DataTable from "../../dataTable";
import LabeledTextField from "../../labeledTextField/labeledTextField";
import { Box } from "@mui/material";
import CustomObjectsDialog from "../../customObjects/customObjectsDialog";

const template: () => dataMatchingToNavBar = () => {
    return {
        id: "tags",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "searchSelect", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null, options: [
                    {
                        "name": "Assets",
                        "id": "5e692ba94d626d13202ccb64"
                    },
                    {
                        "name": "BalanceSheet",
                        "id": "66a181c17a6bd5a793d894ac"
                    },
                    {
                        "name": "CashFlowsStatement",
                        "id": "5ae01cac82ac090a3cb8465f"
                    },
                    {
                        "name": "ComprehensiveIncomeBeforeTax",
                        "id": "5c9c6a3a072d762160ad532e"
                    },
                    {
                        "name": "ComprehensiveIncomeNetOfTax",
                        "id": "5e5622c64d626d19007d3a90"
                    },
                    {
                        "name": "CurrentAssets",
                        "id": "5ae01cac82ac090a3cb84660"
                    },
                    {
                        "name": "CurrentLiabilities",
                        "id": "5dd293774d626d21acca1745"
                    },
                    {
                        "name": "Debt",
                        "id": "5ae01cac82ac090a3cb84661"
                    },
                    {
                        "name": "Disclosures",
                        "id": "5ae01cac82ac090a3cb84672"
                    },
                    {
                        "name": "DST",
                        "id": "5ae01cac82ac090a3cb84658"
                    },
                    {
                        "name": "EmployeeBenefitsExpense",
                        "id": "5ae01cac82ac090a3cb84655"
                    },
                    {
                        "name": "Equity",
                        "id": "5ae01cac82ac090a3cb84662"
                    },
                    {
                        "name": "FinancingActivities",
                        "id": "5ae01cac82ac090a3cb84663"
                    },
                ]
            },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null, },
            isLabel: { key: "isLabel", name: t`Is Label`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            mergePresentation: { key: "mergePresentation", name: t`Merge Presentation`, fieldType: "checkbox", value: null },
            isFootnote: { key: "isFootnote", name: t`Is Footnote`, fieldType: "checkbox", value: null, },
            isArea: { key: "isArea", name: t`Is Area`, fieldType: "checkbox", value: null, },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, },
            context: { key: "context", name: t`Context`, fieldType: "searchSelect", value: null, },
        },
        tableTag: {
            navBarName: t`Table Tag`,
            isTableTag: { key: "isTableTag", name: t`Is Table Tag`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            strictParsing: { key: "strictParsing", name: t`Strict Parsing`, fieldType: "checkbox", value: null },
        },
        dimensionValues: {
            navBarName: t`Dimension Values`,
            dataTable: {
                data: []
            }
        },
        extension: {
            navBarName: t`Extension Hierarchy`,
            dataTable: {
                data: [],
            },
        },
        extensionStructure: {
            navBarName: t`Extension Structure`,
            dataTable: {
                data: [],
            },
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
    }
}

export const TaxonomyElements = (data: any) => {

    const Tablecolumns: () => ColumnProps[] = () => {
        return [
            {
                header: {
                    id: "name",
                    label: t`Name`,
                    optionsHeaderText: t`Name`,
                    shouldBeFiltered: true,
                    inputType: "text",
                    width: 600,
                },
                key: "name",
                width: 600,
            },
            {
                header: {
                    id: "nameSpace",
                    label: t`Name Space`,
                    shouldBeFiltered: true,
                    optionsHeaderText: t`Select Name Space`,
                    inputType: "select",
                    width: 150,
                },
                key: "nameSpace",
                width: 150,
            },
            {
                header: {
                    id: "periodType",
                    label: t`Period Type`,
                    shouldBeFiltered: true,
                    optionsHeaderText: t`Select Period Type`,
                    inputType: "text",
                    width: 250,
                },
                key: "periodType",
                width: 250,
            },
            {
                header: {
                    id: "isCredit",
                    label: t`Is Credit`,
                    shouldBeFiltered: true,
                    inputType: "select",
                    width: 250,
                },
                key: "isCredit",
                width: 250,
            },
        ];
    };

    return (
        <>
            <Box sx={{ mt: 5, alignContent: "center", display: "flex", flexDirection: "column" }}>
                <LabeledTextField
                    label={t`Taxonomy Elements`}
                    fieldType="select"
                    value=""
                    onValueChange={() => { }}
                />
                <DataTable
                    data={[]}
                    columns={Tablecolumns()}
                    noWidth
                    noDataMessage={t`No Taxonomy Elements`}
                />
            </Box>
            <CustomObjectsDialog
                selectTemplate="taxonomyElement"
                open={true}
                onClose={() => { }}
                id={""}
                url={""}
            />
        </>)
};