import { ListItemText, TextField } from "@mui/material"
import Grid from '@mui/material/Grid2'

export const SelectLabel = ({
    label,
    value,
    onValueChange,
    options,
    disabled,
    placeholder
}: {
    label?: string,
    value?: boolean | null,
    options: { label: string, value: boolean | null }[],
    onValueChange?: (event: boolean | null) => void,
    disabled?: boolean,
    placeholder?: string
}) => {
    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            {label && (
                <Grid size={4}>
                    <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                </Grid>
            )}
            <Grid size={8}>
                <TextField
                    select
                    autoComplete='off'
                    sx={{ width: "100%" }}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    placeholder={placeholder || ""}
                    value={value !== null ? String(value) : ""}
                    disabled={disabled}
                    onChange={(event) => {
                        const selectedOption = options.find(option => String(option.value) === event.target.value);
                        if (onValueChange && selectedOption) {
                            onValueChange(selectedOption.value);
                        }
                    }}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value={""}>
                        {` `}
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={String(option.value)}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}