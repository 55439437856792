import { t } from "@lingui/macro";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DimensionValue } from "../../api/types";
import LabeledTextField from "../labeledTextField/labeledTextField";
import { gridSelectionTypes } from "./genericGridTable";

const initialDimensionValue: DimensionValue = {
    dimensionName: "",
    valueName: "",
    name: "",
    isCustom: false,
    onlyWhenConsolidated: null,
    typedMemberValue: null,
    taxonomyValue: {
        dimensionReference: {
            namespaceAbbreviation: "",
            name: "",
            description: "",
        },
        dimensionValueReference: {
            namespaceAbbreviation: "",
            name: "",
            description: "",
        },
    },
};

export const DialogCustomDimensionValue = ({
    open,
    onClose,
    onSave,
    type,
    dimensionValueToEdit,
}: {
    open: boolean;
    onClose: () => void;
    onSave: (dimensionValue: DimensionValue) => void;
    type: gridSelectionTypes;
    dimensionValueToEdit?: DimensionValue | null;
}) => {
    const [dimensionValue, setDimensionValue] = useState<DimensionValue>(dimensionValueToEdit || initialDimensionValue);

    console.log(dimensionValueToEdit)

    useEffect(() => {
        if (dimensionValueToEdit) {
            setDimensionValue(dimensionValueToEdit);
            console.log(dimensionValue)
        } else {
            setDimensionValue(initialDimensionValue);
        }
    }, [dimensionValueToEdit]);

    const handleFieldChange = (fieldPath: string, value: any) => {
        setDimensionValue((prev) => ({
            ...prev,
            [fieldPath]: value,
        }));
    };

    const handleSave = () => {
        onSave(dimensionValue);
        onClose();
    };

    const getDialogTitle = (): string => {
        const titles: Record<gridSelectionTypes, string> = {
            dimension: t`Dimension Value`,
            additionalElementReferences: t`Element Value`,
            extension: t`Extension Value`,
            extensionStructureReferences: t`Select Element`,
            onlyDimension: t`Add Dimension`,
            dimensionValueReferences: t`Dimension Value`,
            headerDimensionValues: t`Dimension Value`,
            labelDimensionValues: t`Dimension Value`,
            dimensionLabels: t`Dimension Label`,
            validators: t`Dimension Value`,
            childDimensionValues: t`Select Element`,
            childElements: t`Select Element`,
            basicList: t`Select Element`,
            settingsLabels: t`Settings Label`,
            hierarchyElementReferences: t`Hierarchy Element`,
        };
        return titles[type] || (dimensionValueToEdit ? t`Edit Value` : t`New Value`);
    };

    const sharedFields = (type: gridSelectionTypes) => {
        switch (type) {
            case "dimensionValueReferences":
                return (
                    <>
                        <LabeledTextField
                            label={t`Dimension`}
                            fieldType="dimension"
                            value={dimensionValue || ""}
                            onValueChange={(value) => setDimensionValue(value as DimensionValue)}
                        />
                        <LabeledTextField
                            label={t`Bind to Consolidated value`}
                            fieldType="select"
                            options={[
                                { label: "True", value: true },
                                { label: "False", value: false },
                            ]}
                            value={dimensionValue.onlyWhenConsolidated}
                            onValueChange={(value) => handleFieldChange("onlyWhenConsolidated", value)}
                        />
                    </>
                );
            case "extensionStructureReferences":
                return (
                    <LabeledTextField
                        label={t`Element`}
                        fieldType="element"
                        value={dimensionValue.name || ""}
                        onValueChange={(value) => handleFieldChange("name", value)}
                    />
                );
            case "headerDimensionValues":
                return (
                    <>
                        <LabeledTextField
                            label={t`Dimension Value`}
                            fieldType="dimension"
                            value={dimensionValue.valueName || ""}
                            onValueChange={(value) => handleFieldChange("valueName", value)}
                        />
                        <LabeledTextField
                            label={t`Typed Member Value`}
                            fieldType="text"
                            value={dimensionValue.typedMemberValue || ""}
                            onValueChange={(value) => handleFieldChange("typedMemberValue", value)}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <LabeledTextField
                            label={t`Typed Member Value`}
                            fieldType="text"
                            value={dimensionValue.typedMemberValue || ""}
                            onValueChange={(value) => handleFieldChange("typedMemberValue", value)}
                        />
                        <LabeledTextField
                            label={t`Bind to Consolidated value`}
                            fieldType="select"
                            options={["True", "False"]}
                            value={dimensionValue.isCustom ? "True" : "False"}
                            onValueChange={(value) => handleFieldChange("isCustom", value === "True")}
                        />
                    </>
                );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        >
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
                {sharedFields(type)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t`Cancel`}</Button>
                <Button onClick={handleSave}>
                    {dimensionValueToEdit ? t`Save Changes` : t`Add`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
