import {
  Typography,
  Button,
  Container,
  Pagination,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { ObjectsWrapper } from "./objectsWrapper";
import {
  Apifilters,
  ISelectTemplate,
  navBarProps,
} from "./../../api/types";
import DataTable from "./../dataTable";
import { useEffect, useRef, useState } from "react";
import { t } from "@lingui/macro";
import CustomObjectsDialog from "./customObjectsDialog";
import { usePostQuery } from "../../hooks/usePostQuery";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import AlertSnackbar from "../alertSnackbar";
import { getColumns } from "./columnsMapping";


interface CutsomTableProps {
  headerTitle: string;
  url: string;
  selectTemplate: ISelectTemplate;
  onUpdate: (
    updateStatus: "error" | "warning" | "info" | "success",
    reason?: string | undefined
  ) => void;
  isConsistencyCheck?: boolean;
  taxonomyChanged: boolean;
  setTaxonomyChanged: (value: boolean) => void;
}

export const CustomObjectsPageComponent = <T extends { result: U[]; totalCount: number, filters: Apifilters[] }, U extends { id: string; }>({
  headerTitle,
  url,
  selectTemplate,
  isConsistencyCheck = false,
  taxonomyChanged,
  setTaxonomyChanged,
}: CutsomTableProps) => {

  const getQuerySelector = (selectTemplate: ISelectTemplate) => {
    switch (selectTemplate) {
      case "tags":
        return {
          filters: [
            { column: "label", ColumnType: 1, filterType: 1, sortDescending: null },
            { column: "tagGroup", ColumnType: 1, filterType: 0, sortDescending: null },
            { column: "company_name", ColumnType: 1, filterType: 1, sortDescending: null },
            { column: "dateSpectrum", ColumnType: 1, filterType: 0, sortDescending: null },
            { column: "isLabel", ColumnType: 1, filterType: 0, sortDescending: null },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sort: true, sortDescending: null },
            { column: "dimensionValueReferences", ColumnType: 1, filterType: 1, sortDescending: null },
          ],
          page: 1,
          pageCount: 20,
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "header":
      case "labels":
      case "tableItems":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "label", ColumnType: 1, filterType: 1 },
            { column: "elementReference", ColumnType: 1, filterType: 1 },
            { column: "company_name", ColumnType: 1, filterType: 1 },
            { column: "tagGroup", ColumnType: 1, filterType: 0 },
            { column: "dateSpectrum", ColumnType: 1, filterType: 0 },
            { column: "lang", ColumnType: 1, filterType: 0 },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sort: true },
            { column: "dimensionValueReferences_dimensionValue_name", ColumnType: 1, filterType: 1 },
            { column: "additionalElementReferences", ColumnType: 1, filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "dimensionValue":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "dimension_name", ColumnType: 1, filterType: 1 },
            { column: "valueName", ColumnType: 1, filterType: 1 },
            { column: "company_name", ColumnType: 1, filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "customDimensions":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "name", ColumnType: 1, filterType: 1 },
            { column: "company_name", ColumnType: 1, filterType: 1 },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sort: true },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "extensionElements":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "name", ColumnType: 1, filterType: 1 },
            { column: "company_name", ColumnType: 1, filterType: 1 },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sort: true },
            { column: "parentReference_name", ColumnType: 1, filterType: 1 },
            { column: "childElementReferences", ColumnType: 1, filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "settings":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "settingName", filterType: 0 },
            { column: "required", columnType: "bool", value: null, filterType: 0 },
            { column: "labels", filterType: 1, sort: false },
            { column: "taxonomies", filterType: 1, sort: true },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "contextSettings":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "context", filterType: 1 },
            { column: "period", filterType: 1 },
            { column: "taxonomies", filterType: 1, sort: true },
            { column: "dimensionValueReferences", filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "taxonomyDimensionValues":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "dimension", ColumnType: 1, filterType: 1 },
            { column: "value", ColumnType: 1, filterType: 1 },
            { column: "isTypedMember", ColumnType: 1, filterType: 1 },
            { column: "isDefault", ColumnType: 1, filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "globalRequests":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "entityReference_name", filterType: 1 },
            { column: "entity", value: null, filterType: 0 },
            { column: "company_name", filterType: 1 },
            { column: "requester_name", value: null, filterType: 0 },
            { column: "", filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      default:
        return {
          page: 1,
          pageCount: 20,
          filters: [],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
    }
  }

  const [cacheBuster] = useState(crypto.randomUUID());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<navBarProps[]>([]);
  const [req, setReq] = useState(() => getQuerySelector(selectTemplate));
  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  useEffect(() => {
    if (!req) {
      setReq(getQuerySelector(selectTemplate));
    }
  }, [cacheBuster, selectTemplate, req]);

  useEffect(() => {
    const hasSortDescendingChanged = req.filters.some((filter: Apifilters) => filter.sortDescending !== undefined);
    if (hasSortDescendingChanged) {
      setReq((prevReq: any) => {
        const newFilters = prevReq.filters.map((filter: Apifilters) => ({
          ...filter,
          sortDescending: filter.sortDescending,
        }));
        const isSame = JSON.stringify(prevReq.filters) === JSON.stringify(newFilters);
        if (isSame) return prevReq;
        return {
          ...prevReq,
          filters: newFilters,
        };
      });
    }
  }, [req.filters]);

  useEffect(() => {
    if (taxonomyChanged) {
      setReq((prevReq: any) => {
        return {
          ...prevReq,
          taxonomyIds: getSelectedTaxonomyIds(),
        };
      });
      setTaxonomyChanged(false);
    }
  }, [taxonomyChanged]);

  const { data, error, loading } = usePostQuery<T>(
    `api/custom-objects/${url}/search?cb=${cacheBuster}`,
    req,
  );

  const previousFiltersRef = useRef<Apifilters[] | null>(null);

  useEffect(() => {
    if (data?.filters) {
      const previousFilters = previousFiltersRef.current;
      const isDifferent = JSON.stringify(previousFilters) !== JSON.stringify(data.filters);

      if (isDifferent) {
        setReq((prevReq) => {
          const newFilters = prevReq.filters.map((filter) => {
            const matchingFilter = data.filters.find((f) => f.column === filter.column);
            return matchingFilter ? { ...filter, ...matchingFilter } : filter;
          });
          return { ...prevReq, filters: newFilters };
        });

        previousFiltersRef.current = data.filters;
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = error.response?.data as { IsTranslated?: boolean; Exceptions?: string[] };
      if (errorData?.IsTranslated && errorData?.Exceptions) {
        setAlertMessage({ message: errorData.Exceptions[0], severity: "error" });
      } else {
        setAlertMessage({ message: t`An unexpected error occurred`, severity: "error" });
      }
    }
  }, [error]);

  const handleSetFilter = (columnId: string, value?: string | boolean, sortDescending?: boolean) => {
    setReq((prevReq: any) => {
      return {
        ...prevReq,
        filters: prevReq.filters.map((filter: any) => {
          if (filter.column === columnId) {
            return { ...filter, value: value === "" ? null : value, sortDescending };
          }
          return filter;
        }),
      };
    });
  };

  useEffect(() => {
    const columns = getColumns(selectTemplate) as any;
    setColumns(columns);
  }, [selectTemplate]);

  const handlecloseDialog = (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => {
    setOpenDialog(false);
    setSelectedId(undefined);
    if (updateStatus === "success" || updateStatus === "error") {
      setAlertMessage({
        message: reason || t`Something went wrong`,
        severity: updateStatus,
      });
    }
  };

  return (
    <ObjectsWrapper>
      <Grid container maxWidth={"false"} alignItems="center" sx={{ p: 2 }}>
        <Grid size={6}>
          <Typography variant="h5" fontWeight={"bold"} sx={{ pl: 2, pb: 2, pt: 2 }}>
            {headerTitle}
          </Typography>
        </Grid>
        <Grid size={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Typography
            sx={{
              display: "inline-block",
              pb: 0.5,
              pt: 0.5,
              pl: 1,
              pr: 4,
              borderRadius: 2,
            }}
          >
            {t`Total:`}
            <span style={{ paddingLeft: 5 }}>{data ? data.totalCount : 0}</span>
          </Typography>
          {!isConsistencyCheck && selectTemplate !== "globalRequests" && (
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              {t`Add`}
            </Button>
          )}
          {isConsistencyCheck && (
            <Button variant="contained" onClick={() => { }}>
              {t`Check`}
            </Button>
          )}
        </Grid>
      </Grid>
      {loading && isConsistencyCheck && (
        <Container sx={{ height: 750, display: 'flex', justifyContent: 'center', alignItems: 'center', color: "grey" }}>
          {selectTemplate === "consistencyCheck" && (
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>
              {t`Please select the appropriate taxonomies and click "Check" to view the results.`}
            </Typography>
          )}
        </Container>
      )}
      <DataTable<U>
        columns={columns}
        data={data?.result || []}
        filters={req.filters || []}
        onClickRow={setSelectedId}
        noDataMessage={t`No data found`}
        setFilter={handleSetFilter}
        renderTableHeader={true}
        loading={loading}
        verticalAlign="top"
      />
      {data && !loading && data.totalCount > 20 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          <Pagination
            count={Math.ceil(data.totalCount / 25)}
            page={req.page}
            onChange={(event, value) => setReq((prevReq) => ({ ...prevReq, page: value }))}
            color="primary"
          />
        </Box>
      )}


      {(selectedId !== undefined || openDialog) && (
        <CustomObjectsDialog
          id={selectedId}
          url={url}
          open={selectedId !== undefined || openDialog}
          onClose={handlecloseDialog}
          selectTemplate={selectTemplate}
        />
      )}
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </ObjectsWrapper>
  );
};
