import { Route, Routes } from "react-router-dom";
import { NotFound } from "../pages/notFound";
import ConversionDetailsPage from "./conversions/conversionDetailsPage";
import Conversions from "./conversions/conversions";
import Login from "./login/login";
import Logout from "./login/logout";
import MicrosoftLoginCallback from "./login/microsoftLoginCallback";
import Navbar from "./navbar";
import { OrganizationById } from "./organizations/organizationById";
import Organizations from "./organizations/organizations";
import Protected from "./protected";
import Taxonomies from "./taxonomies/taxonomies";
import OrganizationUsers from "./users/organizationUsers";
import { UserById } from "./users/userById";
import Users from "./users/users";
import CustomObjects from "./customObjects/customObjects";
import WithAccess from "./withAccess";
import DefaultRoute from "./defaultRoute";
import Validation from "./validation/validation";
import { PermissionSchemes } from "./permissionSchemes/permissionSchemes";
import { TaxonomyById } from "./taxonomies/taxonomyById";

const MainNavigation = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/accounts/login/microsoft/callback"
          element={<MicrosoftLoginCallback />}
        />
        <Route path="/" element={<DefaultRoute />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/users"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <Users />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/users/:id"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <UserById />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                <Taxonomies />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                <TaxonomyById starterPage={"details"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/details"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"details"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/entrypoints"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"entrypoints"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/taxonomyelements"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"taxonomyelements"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/viewer"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"viewer"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/loginformation"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"logInformation"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/taxonomies/:id/taxonomydimensionvalues"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                < TaxonomyById starterPage={"taxonomyDimensionValues"} />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/permissionschemes"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasTaxonomyManagement">
                <PermissionSchemes />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/conversions"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasConversionManagement">
                <Conversions />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/conversions/:id"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasConversionManagement">
                <ConversionDetailsPage />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/organizations"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <Organizations />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/organizations/:id"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <OrganizationById />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/organizations/:id/users"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <OrganizationUsers />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/validation"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasValidationManagement">
                <Validation />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/tags"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/headers"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/labels"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/tableitems"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/dimensions"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/dimensionvalues"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/extensionelements"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/settings"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/contextsettings"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/consistencycheck"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
        <Route
          path="/customobjects/globalrequests"
          element={
            <Protected>
              <WithAccess accessTokenKey="hasUserManagement">
                <CustomObjects />
              </WithAccess>
            </Protected>
          }
        />
      </Routes>
    </>
  );
};

export default MainNavigation;