import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import ParseportImage from "../../src/images/parseport.png";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { IconButton, SvgIconTypeMap } from "@mui/material";
import { t } from "@lingui/macro";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appBarFixedHeight } from "../helpers/constants";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TaskIcon from "@mui/icons-material/Task";
import tokenStorageHelper from "../helpers/tokenStorage";
import { jwtDecode } from "jwt-decode";
import { DecodedToken } from "../api/types";
interface NavbarTab {
  path: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
}

// Renders the top navbar for the application
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  if (!tokenStorageHelper.isUserLoggedIn) return null;
  var decodeToken = jwtDecode(tokenStorageHelper.token) as DecodedToken;
  const tabs: NavbarTab[] = [];
  if (decodeToken) {
    if (decodeToken.hasConversionManagement === "True") {
      tabs.push({
        path: "/conversions",
        label: t`Conversions`,
        icon: LoopOutlinedIcon,
      });
    }
    if (decodeToken.hasTaxonomyManagement === "True") {
      tabs.push({
        path: "/taxonomies",
        label: t`Taxonomies`,
        icon: InsertDriveFileOutlinedIcon,
      });
    }
    if (decodeToken.hasUserManagement === "True") {
      tabs.push({
        path: "/organizations",
        label: t`Organizations`,
        icon: ApartmentOutlinedIcon,
      });
      tabs.push({
        path: "/users",
        label: t`Users`,
        icon: GroupOutlinedIcon,
      });
      tabs.push({
        path: "/validation",
        label: t`Validation`,
        icon: TaskIcon,
      });
      tabs.push({
        path: "/customobjects/tags",
        label: t`Custom Objects`,
        icon: LoopOutlinedIcon,
      });
    }
  }

  if (!tokenStorageHelper.isUserLoggedIn) return null;

  let currentPath: string | boolean = location.pathname.toLowerCase().split('/')[1];
  currentPath = `/${currentPath}`;

  if (!tabs || !tabs.map((t) => t.path).includes(currentPath)) {
    currentPath = false;
  }

  const tabElements = tabs.map((tab) => (
    <Tab
      key={tab.label}
      component={Link}
      to={tab.path}
      value={tab.path}
      label={tab.label}
      iconPosition="start"
      icon={tab.icon && <tab.icon />}
      sx={{ color: (theme) => theme.palette.common.white }}
      {...a11yProps(tab.label)}
    />
  ));

  return (
    <AppBar
      sx={{
        height: appBarFixedHeight,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: process.env.REACT_APP_COLOR ?? "#072a44",
      }}
    >
      <Toolbar>
        <a href="/">
          <img src={ParseportImage} alt="Parseport" style={{ maxHeight: 66 }} />
        </a>
        {tabs && tabs.length > 0 && (
          <Tabs value={currentPath}>{tabElements}</Tabs>
        )}
        <IconButton
          onClick={() => navigate("/logout")}
          sx={{ color: (theme) => theme.palette.common.white, ml: "auto" }}
        >
          <LogoutOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

function a11yProps(value: string) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
  };
}

export default Navbar;
