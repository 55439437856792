import { Box, Typography, Divider, Dialog, DialogContent, DialogTitle, Tabs, Tab, Button, DialogActions } from "@mui/material";
import DataTable from "../../dataTable";
import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import LabeledTextField from "../../labeledTextField/labeledTextField";

export const TaxonomyEntryPoints = () => {
    const [entryPointsData, setEntryPointsData] = useState<TaxonomyEntryPoint[]>([]);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [selectedData, setSelectedData] = useState<TaxonomyEntryPoint | null>(null);
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        if (selectedId) {
            const data = entryPointsData.find((entryPoint) => entryPoint.id === selectedId);
            setSelectedData(data || null);
        }
    }, [selectedId]);

    useEffect(() => {
        setEntryPointsData(hardCodedData);
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    //TODO Implement the save functionality - ensure that the table and data is the correct data used across the board

    return (
        <>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Typography variant="h6">{t`Entry Points`}</Typography>
                <Divider />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                {entryPointsData.length === 0 ? (
                    <Typography>No entry points found</Typography>
                ) : (
                    <DataTable<TaxonomyEntryPoint>
                        data={entryPointsData}
                        columns={[
                            { header: { label: "Schema Ref", id: "schemaRef" }, key: "schemaRef" },
                            {
                                header: { label: "Labels", id: "labels" }, key: "labels", valueFormatter: (row) => row.labels.map(
                                    (label) => `${label.text}`
                                ).join(", "),
                                shouldWrapVertically: true,
                            },
                        ]}
                        noWidth
                        onClickRow={(row) => setSelectedId(row)}
                    />
                )}
            </Box>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={selectedId !== null}
                onClose={() => setSelectedId(null)}
            >
                <DialogTitle>
                    {t`Edit Entry Point: ${selectedId}`}
                </DialogTitle>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="entry point tabs">
                    <Tab label={<span style={{ color: 'black' }}>Details</span>} />
                    <Tab label={<span style={{ color: 'black' }}>Taxonomy Group</span>} />
                </Tabs>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 2,
                        maxHeight: "85vh",
                    }}
                >
                    {currentTab === 0 && (
                        <>
                            <Box sx={{
                                diaply: "flex",
                                flexDirection: "column",
                                padding: 2,
                                width: "100%",
                            }}>
                                <Typography variant="h4" fontWeight={"bold"} sx={{ mb: 2 }}>Header</Typography>
                                <LabeledTextField
                                    label="Schema Ref"
                                    value={selectedData?.schemaRef}
                                    fieldType="text"
                                />
                                <LabeledTextField
                                    label="Target Namespace"
                                    value={selectedData?.schemaRef}
                                    fieldType="text"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const newEntryPoint: TaxonomyEntryPoint = {
                                            id: `new-${Date.now()}`,
                                            schemaRef: "",
                                            labels: [],
                                            taxonomyGroups: [],
                                            targetNamespace: null,
                                            taxonomyId: ""
                                        };
                                        setEntryPointsData([...entryPointsData, newEntryPoint]);
                                        setSelectedId(newEntryPoint.id);
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <DataTable<TaxonomyEntryPoint>
                                data={entryPointsData}
                                columns={[
                                    { header: { label: "Language", id: "lang" }, key: "lang" },
                                    {
                                        header: { label: "Labels", id: "labels" }, key: "labels", valueFormatter: (row) => row.labels.map(
                                            (label) => `${label.text}`
                                        ).join(", "),
                                        shouldWrapVertically: true,
                                    },
                                ]}
                                noWidth
                                onClickRow={(row) => setSelectedId(row)}
                            />
                        </>
                    )}
                    {currentTab === 1 && (
                        <DataTable<TaxonomyGroup>
                            onClickRow={() => { }}
                            data={selectedData?.taxonomyGroups || []}
                            columns={[
                                { header: { label: "Href", id: "href" }, key: "href" },
                                { header: { label: "Official URI", id: "officialUri" }, key: "officialUri" },
                                { header: { label: "Role ID", id: "roleId" }, key: "roleId" },
                                { header: { label: "Relative Path", id: "relativePath" }, key: "relativePath" },
                                { header: { label: "Is Custom", id: "isCustom" }, key: "isCustom" },
                            ]}
                            noWidth
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedId(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => console.log("Save functionality to be implemented")} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

interface TaxonomyGroup {
    href: string;
    officialUri: string;
    roleId: string;
    relativePath: string;
    calculations: any[];
    isCustom: boolean;
    id: string;
}

interface TaxonomyEntryPoint {
    schemaRef: string;
    targetNamespace: string | null;
    taxonomyGroups: TaxonomyGroup[];
    taxonomyId: string;
    labels: {
        lang: string;
        text: string;
        role: string;
        id: string | null;
    }[];
    id: string;
}

const hardCodedData: TaxonomyEntryPoint[] = [
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entryAll-ifrs-dk_2021-12-20.xsd",
        "targetNamespace": null,
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0329ae0f622638047b31"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e032dae0f622638047c5c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0330ae0f622638047db6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0340ae0f622638048404"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0342ae0f622638048488"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0343ae0f6226380484f2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0344ae0f6226380485d2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0344ae0f6226380485dc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0344ae0f6226380485e6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0345ae0f622638048689"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0346ae0f62263804875a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0347ae0f6226380487af"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0348ae0f6226380487da"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0348ae0f62263804882f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e034cae0f62263804894b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e034cae0f622638048967"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e034fae0f622638048a9b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0353ae0f622638048ca3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0355ae0f622638048dd4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0356ae0f622638048e16"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0356ae0f622638048e43"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0357ae0f622638048f0e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0359ae0f62263804902d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0359ae0f62263804904c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e035aae0f62263804905f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e035aae0f622638049066"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e035bae0f622638049142"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e035bae0f62263804915e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e035cae0f6226380491b3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0362ae0f62263804941c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0363ae0f622638049444"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0365ae0f622638049514"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0366ae0f6226380495e0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0368ae0f622638049799"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0370ae0f622638049bb0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e037dae0f62263804a559"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e037dae0f62263804a572"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e037fae0f62263804a66d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0380ae0f62263804a704"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0382ae0f62263804a7db"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0386ae0f62263804a9f7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0386ae0f62263804aa0d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0386ae0f62263804aa64"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0387ae0f62263804aa92"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0388ae0f62263804ab22"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e038aae0f62263804ac19"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e038cae0f62263804ad01"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e038fae0f62263804ae07"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0390ae0f62263804ae68"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0394ae0f62263804af86"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e039aae0f62263804b1f9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03a7ae0f62263804b68f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03a8ae0f62263804b6ef"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03a9ae0f62263804b778"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03adae0f62263804b8ea"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03afae0f62263804b914"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03b1ae0f62263804b9a8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03b2ae0f62263804b9df"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03b3ae0f62263804b9f7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03b4ae0f62263804ba76"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03b6ae0f62263804baa3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03bcae0f62263804bbff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03c7ae0f62263804bd6a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03c7ae0f62263804bd77"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [],
        "id": "619e0329ae0f622638047630"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_01_IS-ByNature_SFP-CurrentNoncurrent_OCI-BeforeTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry01-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03caae0f62263804bdfd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03cdae0f62263804bdfe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03d0ae0f62263804bdff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03ddae0f62263804be00"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03deae0f62263804be01"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e0ae0f62263804be02"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e2ae0f62263804be03"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e2ae0f62263804be04"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e2ae0f62263804be05"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e3ae0f62263804be06"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e4ae0f62263804be07"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e4ae0f62263804be08"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e5ae0f62263804be09"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e5ae0f62263804be0a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e8ae0f62263804be0b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03e8ae0f62263804be0c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03ebae0f62263804be0d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03efae0f62263804be0e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f1ae0f62263804be0f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f1ae0f62263804be10"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f2ae0f62263804be11"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f3ae0f62263804be12"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f5ae0f62263804be13"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f5ae0f62263804be14"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f5ae0f62263804be15"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f5ae0f62263804be16"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f6ae0f62263804be17"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f7ae0f62263804be18"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03f8ae0f62263804be19"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03feae0f62263804be1a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e03ffae0f62263804be1b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0400ae0f62263804be1c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0401ae0f62263804be1d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0404ae0f62263804be1e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e040aae0f62263804be1f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0417ae0f62263804be20"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0417ae0f62263804be21"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0419ae0f62263804be22"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e041aae0f62263804be23"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e041cae0f62263804be24"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e041fae0f62263804be25"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e041fae0f62263804be26"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0420ae0f62263804be27"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0420ae0f62263804be28"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0421ae0f62263804be29"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0424ae0f62263804be2a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0426ae0f62263804be2b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0429ae0f62263804be2c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e042aae0f62263804be2d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e042dae0f62263804be2e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0433ae0f62263804be2f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e043fae0f62263804be30"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0440ae0f62263804be31"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0441ae0f62263804be32"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0445ae0f62263804be33"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0447ae0f62263804be34"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0447ae0f62263804be35"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0448ae0f62263804be36"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0453ae0f62263804be37"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0453ae0f62263804be38"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e03caae0f62263804bdfc"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_02_IS-ByNature_SFP-CurrentNoncurrent_OCI-NetOfTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry02-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0453ae0f62263804be3b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0456ae0f62263804be3c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0459ae0f62263804be3d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0468ae0f62263804be3e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0469ae0f62263804be3f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0469ae0f62263804be40"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046bae0f62263804be41"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046bae0f62263804be42"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046bae0f62263804be43"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046cae0f62263804be44"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046dae0f62263804be45"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046eae0f62263804be46"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046eae0f62263804be47"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e046fae0f62263804be48"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0472ae0f62263804be49"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0472ae0f62263804be4a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0475ae0f62263804be4b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0479ae0f62263804be4c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e047cae0f62263804be4d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e047cae0f62263804be4e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e047cae0f62263804be4f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e047eae0f62263804be50"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e047fae0f62263804be51"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0480ae0f62263804be52"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0480ae0f62263804be53"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0480ae0f62263804be54"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0481ae0f62263804be55"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0482ae0f62263804be56"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0483ae0f62263804be57"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0488ae0f62263804be58"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0489ae0f62263804be59"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e048bae0f62263804be5a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e048cae0f62263804be5b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e048eae0f62263804be5c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0494ae0f62263804be5d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04a2ae0f62263804be5e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04a2ae0f62263804be5f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04a4ae0f62263804be60"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04a5ae0f62263804be61"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04a7ae0f62263804be62"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04aaae0f62263804be63"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04aaae0f62263804be64"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04abae0f62263804be65"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04abae0f62263804be66"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04acae0f62263804be67"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04aeae0f62263804be68"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04b0ae0f62263804be69"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04b5ae0f62263804be6a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04b6ae0f62263804be6b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04b8ae0f62263804be6c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04beae0f62263804be6d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04cbae0f62263804be6e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04ccae0f62263804be6f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04ccae0f62263804be70"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04d0ae0f62263804be71"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04d2ae0f62263804be72"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04d2ae0f62263804be73"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04d4ae0f62263804be74"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04deae0f62263804be75"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04deae0f62263804be76"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0453ae0f62263804be3a"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_03_IS-ByFunction_SFP-CurrentNoncurrent_OCI-BeforeTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry03-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04deae0f62263804be79"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04e1ae0f62263804be7a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04e4ae0f62263804be7b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f1ae0f62263804be7c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f2ae0f62263804be7d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f3ae0f62263804be7e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f5ae0f62263804be7f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f5ae0f62263804be80"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f5ae0f62263804be81"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f6ae0f62263804be82"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f6ae0f62263804be83"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f7ae0f62263804be84"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f8ae0f62263804be85"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04f9ae0f62263804be86"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04fcae0f62263804be87"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04fcae0f62263804be88"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e04ffae0f62263804be89"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0502ae0f62263804be8a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0505ae0f62263804be8b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0505ae0f62263804be8c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0505ae0f62263804be8d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0507ae0f62263804be8e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0508ae0f62263804be8f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0509ae0f62263804be90"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0509ae0f62263804be91"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0509ae0f62263804be92"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e050aae0f62263804be93"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e050aae0f62263804be94"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e050bae0f62263804be95"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0512ae0f62263804be96"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0513ae0f62263804be97"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0515ae0f62263804be98"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0516ae0f62263804be99"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0518ae0f62263804be9a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e051eae0f62263804be9b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e052dae0f62263804be9c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e052dae0f62263804be9d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e052fae0f62263804be9e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0531ae0f62263804be9f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0532ae0f62263804bea0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0536ae0f62263804bea1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0536ae0f62263804bea2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0536ae0f62263804bea3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0537ae0f62263804bea4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0538ae0f62263804bea5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e053aae0f62263804bea6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e053eae0f62263804bea7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0541ae0f62263804bea8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0542ae0f62263804bea9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0544ae0f62263804beaa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e054aae0f62263804beab"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0557ae0f62263804beac"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0558ae0f62263804bead"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0559ae0f62263804beae"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e055cae0f62263804beaf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e055eae0f62263804beb0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e055eae0f62263804beb1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e055fae0f62263804beb2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0569ae0f62263804beb3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0569ae0f62263804beb4"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e04deae0f62263804be78"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_04_IS-ByFunction_SFP-CurrentNoncurrent_OCI-NetOfTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry04-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e056aae0f62263804beb7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e056cae0f62263804beb8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e056fae0f62263804beb9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e057eae0f62263804beba"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e057fae0f62263804bebb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e057fae0f62263804bebc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0581ae0f62263804bebd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0581ae0f62263804bebe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0581ae0f62263804bebf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0582ae0f62263804bec0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0583ae0f62263804bec1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0584ae0f62263804bec2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0584ae0f62263804bec3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0585ae0f62263804bec4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0588ae0f62263804bec5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0589ae0f62263804bec6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e058cae0f62263804bec7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e058fae0f62263804bec8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0592ae0f62263804bec9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0593ae0f62263804beca"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0593ae0f62263804becb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0594ae0f62263804becc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0596ae0f62263804becd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0596ae0f62263804bece"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0596ae0f62263804becf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0596ae0f62263804bed0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0598ae0f62263804bed1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0598ae0f62263804bed2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0599ae0f62263804bed3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e059eae0f62263804bed4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e059fae0f62263804bed5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05a1ae0f62263804bed6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05a2ae0f62263804bed7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05a4ae0f62263804bed8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05aaae0f62263804bed9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05beae0f62263804beda"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05bfae0f62263804bedb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c0ae0f62263804bedc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c1ae0f62263804bedd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c3ae0f62263804bede"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c7ae0f62263804bedf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c7ae0f62263804bee0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c8ae0f62263804bee1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c8ae0f62263804bee2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05c9ae0f62263804bee3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05ccae0f62263804bee4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05ceae0f62263804bee5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05d1ae0f62263804bee6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05d2ae0f62263804bee7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05d5ae0f62263804bee8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05dbae0f62263804bee9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05e6ae0f62263804beea"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05e7ae0f62263804beeb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05e8ae0f62263804beec"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05ecae0f62263804beed"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05edae0f62263804beee"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05eeae0f62263804beef"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05efae0f62263804bef0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05faae0f62263804bef1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05faae0f62263804bef2"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e056aae0f62263804beb6"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_05_IS-ByNature_SFP-OrderOfLiquidity_OCI-BeforeTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry05-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05faae0f62263804bef5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e05fdae0f62263804bef6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0600ae0f62263804bef7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e060dae0f62263804bef8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e060eae0f62263804bef9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e060fae0f62263804befa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0611ae0f62263804befb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0611ae0f62263804befc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0611ae0f62263804befd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0612ae0f62263804befe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0613ae0f62263804beff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0614ae0f62263804bf00"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0615ae0f62263804bf01"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0616ae0f62263804bf02"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0618ae0f62263804bf03"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0618ae0f62263804bf04"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e061cae0f62263804bf05"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e061eae0f62263804bf06"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0622ae0f62263804bf07"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0623ae0f62263804bf08"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0623ae0f62263804bf09"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0625ae0f62263804bf0a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0629ae0f62263804bf0b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0629ae0f62263804bf0c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e062aae0f62263804bf0d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e062aae0f62263804bf0e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e062cae0f62263804bf0f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e062cae0f62263804bf10"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e062dae0f62263804bf11"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0633ae0f62263804bf12"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0634ae0f62263804bf13"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0636ae0f62263804bf14"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0637ae0f62263804bf15"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e063aae0f62263804bf16"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0641ae0f62263804bf17"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e064eae0f62263804bf18"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e064eae0f62263804bf19"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0650ae0f62263804bf1a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0651ae0f62263804bf1b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0652ae0f62263804bf1c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0656ae0f62263804bf1d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0657ae0f62263804bf1e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0657ae0f62263804bf1f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0657ae0f62263804bf20"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0658ae0f62263804bf21"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e065aae0f62263804bf22"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e065cae0f62263804bf23"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0660ae0f62263804bf24"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0660ae0f62263804bf25"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0663ae0f62263804bf26"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e066aae0f62263804bf27"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0676ae0f62263804bf28"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0677ae0f62263804bf29"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0678ae0f62263804bf2a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e067bae0f62263804bf2b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e067dae0f62263804bf2c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e067dae0f62263804bf2d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e067eae0f62263804bf2e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0684ae0f62263804bf2f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0684ae0f62263804bf30"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e05faae0f62263804bef4"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_06_IS-ByNature_SFP-OrderOfLiquidity_OCI-NetOfTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry06-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0684ae0f62263804bf33"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0687ae0f62263804bf34"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e068aae0f62263804bf35"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0699ae0f62263804bf36"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069aae0f62263804bf37"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069bae0f62263804bf38"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069dae0f62263804bf39"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069dae0f62263804bf3a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069dae0f62263804bf3b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069eae0f62263804bf3c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069eae0f62263804bf3d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e069fae0f62263804bf3e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06a0ae0f62263804bf3f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06a1ae0f62263804bf40"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06a3ae0f62263804bf41"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06a4ae0f62263804bf42"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06a6ae0f62263804bf43"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06aaae0f62263804bf44"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06adae0f62263804bf45"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06aeae0f62263804bf46"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06afae0f62263804bf47"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b0ae0f62263804bf48"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b2ae0f62263804bf49"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b2ae0f62263804bf4a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b3ae0f62263804bf4b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b3ae0f62263804bf4c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b4ae0f62263804bf4d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b4ae0f62263804bf4e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06b5ae0f62263804bf4f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06bbae0f62263804bf50"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06bcae0f62263804bf51"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06beae0f62263804bf52"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06bfae0f62263804bf53"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06c2ae0f62263804bf54"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06c7ae0f62263804bf55"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06d5ae0f62263804bf56"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06d5ae0f62263804bf57"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06d7ae0f62263804bf58"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06d8ae0f62263804bf59"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06d9ae0f62263804bf5a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06ddae0f62263804bf5b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06deae0f62263804bf5c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06deae0f62263804bf5d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06deae0f62263804bf5e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06dfae0f62263804bf5f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06e2ae0f62263804bf60"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06e5ae0f62263804bf61"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06e8ae0f62263804bf62"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06e9ae0f62263804bf63"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06ecae0f62263804bf64"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06f2ae0f62263804bf65"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06ffae0f62263804bf66"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e06ffae0f62263804bf67"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0700ae0f62263804bf68"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0704ae0f62263804bf69"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0705ae0f62263804bf6a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0705ae0f62263804bf6b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0706ae0f62263804bf6c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e070eae0f62263804bf6d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e070eae0f62263804bf6e"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0684ae0f62263804bf32"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_07_IS-ByFunction_SFP-OrderOfLiquidity_OCI-BeforeTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry07-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e070eae0f62263804bf71"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0711ae0f62263804bf72"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0714ae0f62263804bf73"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0723ae0f62263804bf74"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0724ae0f62263804bf75"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0726ae0f62263804bf76"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0728ae0f62263804bf77"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0728ae0f62263804bf78"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0728ae0f62263804bf79"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0729ae0f62263804bf7a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0729ae0f62263804bf7b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e072aae0f62263804bf7c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e072aae0f62263804bf7d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e072bae0f62263804bf7e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e072eae0f62263804bf7f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e072fae0f62263804bf80"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0732ae0f62263804bf81"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0736ae0f62263804bf82"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0739ae0f62263804bf83"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073aae0f62263804bf84"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073aae0f62263804bf85"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073cae0f62263804bf86"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073eae0f62263804bf87"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073eae0f62263804bf88"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073eae0f62263804bf89"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e073eae0f62263804bf8a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0740ae0f62263804bf8b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0740ae0f62263804bf8c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0741ae0f62263804bf8d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0747ae0f62263804bf8e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0748ae0f62263804bf8f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0749ae0f62263804bf90"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e074bae0f62263804bf91"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e074dae0f62263804bf92"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0754ae0f62263804bf93"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0763ae0f62263804bf94"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0763ae0f62263804bf95"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0765ae0f62263804bf96"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0766ae0f62263804bf97"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0768ae0f62263804bf98"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e076cae0f62263804bf99"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e076cae0f62263804bf9a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e076cae0f62263804bf9b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e076dae0f62263804bf9c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e076eae0f62263804bf9d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0770ae0f62263804bf9e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0773ae0f62263804bf9f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0777ae0f62263804bfa0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0778ae0f62263804bfa1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e077bae0f62263804bfa2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0782ae0f62263804bfa3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e078fae0f62263804bfa4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0791ae0f62263804bfa5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0792ae0f62263804bfa6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0797ae0f62263804bfa7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0799ae0f62263804bfa8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0799ae0f62263804bfa9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e079aae0f62263804bfaa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07a1ae0f62263804bfab"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07a1ae0f62263804bfac"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e070eae0f62263804bf70"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_08_IS-ByFunction_SFP-OrderOfLiquidity_OCI-NetOfTax_CF-IndirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry08-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07a1ae0f62263804bfaf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07a4ae0f62263804bfb0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07a8ae0f62263804bfb1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07b6ae0f62263804bfb2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07b8ae0f62263804bfb3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07b9ae0f62263804bfb4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07bcae0f62263804bfb5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07bcae0f62263804bfb6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07bcae0f62263804bfb7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07bdae0f62263804bfb8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07beae0f62263804bfb9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07bfae0f62263804bfba"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c0ae0f62263804bfbb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c1ae0f62263804bfbc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c3ae0f62263804bfbd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c3ae0f62263804bfbe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c6ae0f62263804bfbf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07c9ae0f62263804bfc0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cbae0f62263804bfc1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07ccae0f62263804bfc2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07ccae0f62263804bfc3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cdae0f62263804bfc4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cfae0f62263804bfc5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cfae0f62263804bfc6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cfae0f62263804bfc7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07cfae0f62263804bfc8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07d1ae0f62263804bfc9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07d1ae0f62263804bfca"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07d2ae0f62263804bfcb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07d8ae0f62263804bfcc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07d9ae0f62263804bfcd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07dbae0f62263804bfce"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07dcae0f62263804bfcf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07dfae0f62263804bfd0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07e4ae0f62263804bfd1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07efae0f62263804bfd2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f0ae0f62263804bfd3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f1ae0f62263804bfd4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f2ae0f62263804bfd5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f3ae0f62263804bfd6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f7ae0f62263804bfd7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f8ae0f62263804bfd8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f8ae0f62263804bfd9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07f9ae0f62263804bfda"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07fbae0f62263804bfdb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e07feae0f62263804bfdc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0803ae0f62263804bfdd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0809ae0f62263804bfde"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e080bae0f62263804bfdf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0810ae0f62263804bfe0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0819ae0f62263804bfe1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0825ae0f62263804bfe2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0826ae0f62263804bfe3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0827ae0f62263804bfe4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e082aae0f62263804bfe5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e082bae0f62263804bfe6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e082bae0f62263804bfe7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e082cae0f62263804bfe8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0834ae0f62263804bfe9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0834ae0f62263804bfea"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (indirect)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e07a1ae0f62263804bfae"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_09_IS-ByNature_SFP-CurrentNoncurrent_OCI-BeforeTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry09-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0834ae0f62263804bfed"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0837ae0f62263804bfee"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e083aae0f62263804bfef"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0849ae0f62263804bff0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084aae0f62263804bff1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084bae0f62263804bff2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084cae0f62263804bff3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084cae0f62263804bff4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084dae0f62263804bff5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084dae0f62263804bff6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084eae0f62263804bff7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084fae0f62263804bff8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e084fae0f62263804bff9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0850ae0f62263804bffa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0853ae0f62263804bffb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0853ae0f62263804bffc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0857ae0f62263804bffd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e085aae0f62263804bffe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e085dae0f62263804bfff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e085eae0f62263804c000"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e085eae0f62263804c001"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0860ae0f62263804c002"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0862ae0f62263804c003"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0862ae0f62263804c004"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0862ae0f62263804c005"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0862ae0f62263804c006"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0864ae0f62263804c007"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0864ae0f62263804c008"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0865ae0f62263804c009"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e086aae0f62263804c00a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e086bae0f62263804c00b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e086dae0f62263804c00c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e086eae0f62263804c00d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0871ae0f62263804c00e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0877ae0f62263804c00f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0885ae0f62263804c010"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0885ae0f62263804c011"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0887ae0f62263804c012"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0888ae0f62263804c013"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0889ae0f62263804c014"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e088eae0f62263804c015"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e088fae0f62263804c016"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e088fae0f62263804c017"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e088fae0f62263804c018"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0891ae0f62263804c019"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0893ae0f62263804c01a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0896ae0f62263804c01b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0899ae0f62263804c01c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e089aae0f62263804c01d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e089dae0f62263804c01e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08a4ae0f62263804c01f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08afae0f62263804c020"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b0ae0f62263804c021"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b0ae0f62263804c022"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b3ae0f62263804c023"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b5ae0f62263804c024"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b5ae0f62263804c025"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08b7ae0f62263804c026"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08c0ae0f62263804c027"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08c0ae0f62263804c028"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0834ae0f62263804bfec"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_10_IS-ByNature_SFP-CurrentNoncurrent_OCI-NetOfTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry10-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08c0ae0f62263804c02b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08c3ae0f62263804c02c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08c6ae0f62263804c02d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d5ae0f62263804c02e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d6ae0f62263804c02f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d7ae0f62263804c030"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d9ae0f62263804c031"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d9ae0f62263804c032"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08d9ae0f62263804c033"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08daae0f62263804c034"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08dbae0f62263804c035"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08dcae0f62263804c036"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08dcae0f62263804c037"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08ddae0f62263804c038"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08dfae0f62263804c039"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08dfae0f62263804c03a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08e2ae0f62263804c03b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08e6ae0f62263804c03c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08e8ae0f62263804c03d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08e9ae0f62263804c03e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08e9ae0f62263804c03f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08eaae0f62263804c040"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08ecae0f62263804c041"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08ecae0f62263804c042"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08edae0f62263804c043"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08edae0f62263804c044"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08eeae0f62263804c045"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08efae0f62263804c046"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08f0ae0f62263804c047"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08f6ae0f62263804c048"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08f7ae0f62263804c049"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08f9ae0f62263804c04a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08fbae0f62263804c04b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e08fdae0f62263804c04c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0903ae0f62263804c04d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0912ae0f62263804c04e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0912ae0f62263804c04f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0914ae0f62263804c050"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0915ae0f62263804c051"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0916ae0f62263804c052"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091bae0f62263804c053"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091bae0f62263804c054"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091bae0f62263804c055"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091cae0f62263804c056"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091dae0f62263804c057"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e091fae0f62263804c058"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0921ae0f62263804c059"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0925ae0f62263804c05a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0926ae0f62263804c05b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0929ae0f62263804c05c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0930ae0f62263804c05d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e093dae0f62263804c05e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e093eae0f62263804c05f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e093fae0f62263804c060"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0941ae0f62263804c061"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0943ae0f62263804c062"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0943ae0f62263804c063"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0944ae0f62263804c064"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e094fae0f62263804c065"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e094fae0f62263804c066"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e08c0ae0f62263804c02a"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_11_IS-ByFunction_SFP-CurrentNoncurrent_OCI-BeforeTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry11-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e094fae0f62263804c069"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0952ae0f62263804c06a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0955ae0f62263804c06b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0963ae0f62263804c06c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0964ae0f62263804c06d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0965ae0f62263804c06e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0967ae0f62263804c06f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0967ae0f62263804c070"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0967ae0f62263804c071"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0968ae0f62263804c072"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0969ae0f62263804c073"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0969ae0f62263804c074"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e096aae0f62263804c075"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e096bae0f62263804c076"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e096dae0f62263804c077"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e096eae0f62263804c078"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0971ae0f62263804c079"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0975ae0f62263804c07a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0978ae0f62263804c07b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0979ae0f62263804c07c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0979ae0f62263804c07d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097aae0f62263804c07e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097cae0f62263804c07f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097cae0f62263804c080"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097dae0f62263804c081"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097dae0f62263804c082"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097eae0f62263804c083"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097eae0f62263804c084"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e097fae0f62263804c085"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0984ae0f62263804c086"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0985ae0f62263804c087"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0987ae0f62263804c088"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0988ae0f62263804c089"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e098bae0f62263804c08a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0992ae0f62263804c08b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09a1ae0f62263804c08c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09a1ae0f62263804c08d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09a2ae0f62263804c08e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09a3ae0f62263804c08f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09a4ae0f62263804c090"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09aaae0f62263804c091"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09aaae0f62263804c092"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09abae0f62263804c093"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09acae0f62263804c094"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09adae0f62263804c095"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09b0ae0f62263804c096"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09b4ae0f62263804c097"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09b9ae0f62263804c098"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09baae0f62263804c099"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09beae0f62263804c09a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09c5ae0f62263804c09b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d2ae0f62263804c09c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d3ae0f62263804c09d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d3ae0f62263804c09e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d6ae0f62263804c09f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d8ae0f62263804c0a0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09d8ae0f62263804c0a1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09daae0f62263804c0a2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09e5ae0f62263804c0a3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09e5ae0f62263804c0a4"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e094fae0f62263804c068"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_12_IS-ByFunction_SFP-CurrentNoncurrent_OCI-NetOfTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry12-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09e5ae0f62263804c0a7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09e9ae0f62263804c0a8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09ecae0f62263804c0a9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09fbae0f62263804c0aa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09fcae0f62263804c0ab"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09fdae0f62263804c0ac"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09ffae0f62263804c0ad"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09ffae0f62263804c0ae"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e09ffae0f62263804c0af"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a00ae0f62263804c0b0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a01ae0f62263804c0b1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a02ae0f62263804c0b2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a02ae0f62263804c0b3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a04ae0f62263804c0b4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a07ae0f62263804c0b5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a07ae0f62263804c0b6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a0aae0f62263804c0b7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a0dae0f62263804c0b8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a10ae0f62263804c0b9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a10ae0f62263804c0ba"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a11ae0f62263804c0bb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a12ae0f62263804c0bc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a13ae0f62263804c0bd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a14ae0f62263804c0be"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a14ae0f62263804c0bf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a14ae0f62263804c0c0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a15ae0f62263804c0c1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a15ae0f62263804c0c2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a16ae0f62263804c0c3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a1dae0f62263804c0c4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a1eae0f62263804c0c5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a1fae0f62263804c0c6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a21ae0f62263804c0c7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a24ae0f62263804c0c8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a2aae0f62263804c0c9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a3aae0f62263804c0ca"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a3aae0f62263804c0cb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a3cae0f62263804c0cc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a3dae0f62263804c0cd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a3eae0f62263804c0ce"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a42ae0f62263804c0cf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a43ae0f62263804c0d0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a43ae0f62263804c0d1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a44ae0f62263804c0d2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a45ae0f62263804c0d3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a47ae0f62263804c0d4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a4aae0f62263804c0d5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a4dae0f62263804c0d6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a4eae0f62263804c0d7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a51ae0f62263804c0d8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a58ae0f62263804c0d9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a64ae0f62263804c0da"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a65ae0f62263804c0db"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a65ae0f62263804c0dc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a68ae0f62263804c0dd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a6aae0f62263804c0de"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a6aae0f62263804c0df"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a6bae0f62263804c0e0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a75ae0f62263804c0e1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a76ae0f62263804c0e2"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (current/non-current), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e09e5ae0f62263804c0a6"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_13_IS-ByNature_SFP-OrderOfLiquidity_OCI-BeforeTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry13-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a76ae0f62263804c0e5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a78ae0f62263804c0e6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a7bae0f62263804c0e7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a8cae0f62263804c0e8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a8dae0f62263804c0e9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a8eae0f62263804c0ea"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a90ae0f62263804c0eb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a90ae0f62263804c0ec"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a90ae0f62263804c0ed"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a91ae0f62263804c0ee"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a92ae0f62263804c0ef"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a93ae0f62263804c0f0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a93ae0f62263804c0f1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a94ae0f62263804c0f2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a97ae0f62263804c0f3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a97ae0f62263804c0f4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a9aae0f62263804c0f5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0a9dae0f62263804c0f6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa0ae0f62263804c0f7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa0ae0f62263804c0f8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa1ae0f62263804c0f9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa2ae0f62263804c0fa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa5ae0f62263804c0fb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa5ae0f62263804c0fc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa5ae0f62263804c0fd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa5ae0f62263804c0fe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa7ae0f62263804c0ff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa8ae0f62263804c100"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aa9ae0f62263804c101"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ab2ae0f62263804c102"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ab3ae0f62263804c103"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ab5ae0f62263804c104"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ab7ae0f62263804c105"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ab9ae0f62263804c106"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ac1ae0f62263804c107"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad0ae0f62263804c108"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad0ae0f62263804c109"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad1ae0f62263804c10a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad3ae0f62263804c10b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad4ae0f62263804c10c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad8ae0f62263804c10d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad8ae0f62263804c10e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad8ae0f62263804c10f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ad9ae0f62263804c110"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0adaae0f62263804c111"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0adcae0f62263804c112"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0adfae0f62263804c113"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ae2ae0f62263804c114"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ae3ae0f62263804c115"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ae8ae0f62263804c116"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0aeeae0f62263804c117"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0afaae0f62263804c118"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0afbae0f62263804c119"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0afcae0f62263804c11a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b00ae0f62263804c11b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b01ae0f62263804c11c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b01ae0f62263804c11d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b03ae0f62263804c11e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b09ae0f62263804c11f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b09ae0f62263804c120"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0a76ae0f62263804c0e4"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_14_IS-ByNature_SFP-OrderOfLiquidity_OCI-NetOfTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry14-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b09ae0f62263804c123"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b0cae0f62263804c124"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b0fae0f62263804c125"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b20ae0f62263804c126"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b21ae0f62263804c127"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b22ae0f62263804c128"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b23ae0f62263804c129"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b23ae0f62263804c12a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b24ae0f62263804c12b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b24ae0f62263804c12c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b25ae0f62263804c12d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b27ae0f62263804c12e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b27ae0f62263804c12f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b28ae0f62263804c130"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b2aae0f62263804c131"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b2bae0f62263804c132"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b2eae0f62263804c133"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b31ae0f62263804c134"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b35ae0f62263804c135"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b36ae0f62263804c136"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b36ae0f62263804c137"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b37ae0f62263804c138"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b39ae0f62263804c139"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b39ae0f62263804c13a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b39ae0f62263804c13b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b39ae0f62263804c13c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b3bae0f62263804c13d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b3bae0f62263804c13e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b3cae0f62263804c13f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b42ae0f62263804c140"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b43ae0f62263804c141"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b45ae0f62263804c142"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b46ae0f62263804c143"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b49ae0f62263804c144"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b50ae0f62263804c145"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b5fae0f62263804c146"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b5fae0f62263804c147"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b61ae0f62263804c148"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b62ae0f62263804c149"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b64ae0f62263804c14a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b67ae0f62263804c14b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b68ae0f62263804c14c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b68ae0f62263804c14d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b69ae0f62263804c14e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b6aae0f62263804c14f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b6dae0f62263804c150"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b6fae0f62263804c151"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b73ae0f62263804c152"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b74ae0f62263804c153"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b77ae0f62263804c154"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b7fae0f62263804c155"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b8aae0f62263804c156"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b8bae0f62263804c157"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b8cae0f62263804c158"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b8eae0f62263804c159"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b90ae0f62263804c15a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b90ae0f62263804c15b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b92ae0f62263804c15c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b98ae0f62263804c15d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b98ae0f62263804c15e"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by nature), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0b09ae0f62263804c122"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_15_IS-ByFunction_SFP-OrderOfLiquidity_OCI-BeforeTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry15-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b98ae0f62263804c161"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b9bae0f62263804c162"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0b9dae0f62263804c163"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0baeae0f62263804c164"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bafae0f62263804c165"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb0ae0f62263804c166"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb1ae0f62263804c167"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb1ae0f62263804c168"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb2ae0f62263804c169"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb3ae0f62263804c16a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb4ae0f62263804c16b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb5ae0f62263804c16c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb6ae0f62263804c16d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bb7ae0f62263804c16e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bbbae0f62263804c16f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bbbae0f62263804c170"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bbeae0f62263804c171"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc1ae0f62263804c172"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc4ae0f62263804c173"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc5ae0f62263804c174"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc5ae0f62263804c175"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc6ae0f62263804c176"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc8ae0f62263804c177"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc9ae0f62263804c178"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc9ae0f62263804c179"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bc9ae0f62263804c17a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bcaae0f62263804c17b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bcbae0f62263804c17c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bccae0f62263804c17d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bd2ae0f62263804c17e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bd3ae0f62263804c17f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bd5ae0f62263804c180"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bd6ae0f62263804c181"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bd9ae0f62263804c182"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bdfae0f62263804c183"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0befae0f62263804c184"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0befae0f62263804c185"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf1ae0f62263804c186"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf2ae0f62263804c187"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf4ae0f62263804c188"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf8ae0f62263804c189"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf8ae0f62263804c18a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf9ae0f62263804c18b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bf9ae0f62263804c18c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bfaae0f62263804c18d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0bfcae0f62263804c18e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c01ae0f62263804c18f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c05ae0f62263804c190"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c06ae0f62263804c191"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c0aae0f62263804c192"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c14ae0f62263804c193"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c21ae0f62263804c194"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c22ae0f62263804c195"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c22ae0f62263804c196"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c26ae0f62263804c197"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c27ae0f62263804c198"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c27ae0f62263804c199"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c29ae0f62263804c19a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c2fae0f62263804c19b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c30ae0f62263804c19c"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (before tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0b98ae0f62263804c160"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_16_IS-ByFunction_SFP-OrderOfLiquidity_OCI-NetOfTax_CF-DirectMethod_2021-12-20.xsd",
        "targetNamespace": "http://xbrl.dcca.dk/ifrs-dk/entry16-ifrs-dk_2021-12-20",
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/102.00/AuditorsReportsFrom20180630",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xml",
                "roleId": "arr_10200_AuditorsReportsFrom20180630",
                "relativePath": "http://arr/full_arr/arr_1NN.NN_2021-12-20/rol_arr_1NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c30ae0f62263804c19f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/000.00/GeneralAndSubmissionData",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "roleId": "gsd_00000_GeneralAndSubmissionData",
                "relativePath": "http://gsd/full_gsd/gsd_000.00_2021-12-20/rol_gsd_000.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c34ae0f62263804c1a0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/200.00/ManagementsReview",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "roleId": "mrv_20000_ManagementsReview",
                "relativePath": "http://mrv/full_mrv/mrv_200.NN_2021-12-20/rol_mrv_200.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c37ae0f62263804c1a1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/300.00/StatementOfExecutiveAndSupervisoryBoards",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "roleId": "sob_30000_StatementOfExecutiveAndSupervisoryBoards",
                "relativePath": "http://sob/full_sob/sob_300.00_2021-12-20/rol_sob_300.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c49ae0f62263804c1a2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4aae0f62263804c1a3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4bae0f62263804c1a4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4cae0f62263804c1a5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4cae0f62263804c1a6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4dae0f62263804c1a7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4dae0f62263804c1a8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4eae0f62263804c1a9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c4fae0f62263804c1aa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c50ae0f62263804c1ab"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c51ae0f62263804c1ac"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c54ae0f62263804c1ad"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c54ae0f62263804c1ae"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c58ae0f62263804c1af"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c5dae0f62263804c1b0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c5fae0f62263804c1b1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c60ae0f62263804c1b2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c60ae0f62263804c1b3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c61ae0f62263804c1b4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c63ae0f62263804c1b5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c63ae0f62263804c1b6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c64ae0f62263804c1b7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c64ae0f62263804c1b8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c65ae0f62263804c1b9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c66ae0f62263804c1ba"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c67ae0f62263804c1bb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c6eae0f62263804c1bc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c70ae0f62263804c1bd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c72ae0f62263804c1be"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c73ae0f62263804c1bf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c76ae0f62263804c1c0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c7cae0f62263804c1c1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c8aae0f62263804c1c2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c8aae0f62263804c1c3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c8cae0f62263804c1c4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c8dae0f62263804c1c5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c8fae0f62263804c1c6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c93ae0f62263804c1c7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c93ae0f62263804c1c8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c94ae0f62263804c1c9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c94ae0f62263804c1ca"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c95ae0f62263804c1cb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c97ae0f62263804c1cc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c9aae0f62263804c1cd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c9eae0f62263804c1ce"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0c9fae0f62263804c1cf"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ca2ae0f62263804c1d0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ca9ae0f62263804c1d1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cb7ae0f62263804c1d2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cb8ae0f62263804c1d3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cb9ae0f62263804c1d4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cbbae0f62263804c1d5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cbcae0f62263804c1d6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cbdae0f62263804c1d7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cbeae0f62263804c1d8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cc6ae0f62263804c1d9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cc6ae0f62263804c1da"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [
            {
                "lang": "da",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            },
            {
                "lang": "en",
                "text": "Income statement (by function), Financial position (order of liquidity), Comprehensive income (net of tax), Cash flow (direct)",
                "role": "standard",
                "id": null
            }
        ],
        "id": "619e0c30ae0f62263804c19e"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/entry-ifrs-dk_2021-12-20.xsd",
        "targetNamespace": null,
        "taxonomyGroups": [
            {
                "href": "http://xbrl.dcca.dk/role/880.000/AdditionalInformation",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_880000_AdditionalInformation",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cc6ae0f62263804c1dc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/871.100/NotesOperatingSegments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_871100_NotesOperatingSegments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cc8ae0f62263804c1dd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.500/NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868500_NotesMembersSharesInCoOperativeEntitiesAndSimilarInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ccbae0f62263804c1de"
            },
            {
                "href": "http://xbrl.dcca.dk/role/868.200/NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_868200_NotesRightsToInterestsArisingFromDecommissioningRestorationAndEnvironmentalRehabilitationFunds",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ccbae0f62263804c1df"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.200/NotesShareCapitalReservesAndOtherEquityInterest",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861200_NotesShareCapitalReservesAndOtherEquityInterest",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ccbae0f62263804c1e0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/861.000/NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_861000_NotesAnalysisOfOtherComprehensiveIncomeByItem",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cccae0f62263804c1e1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/851.100/NotesCashFlowStatement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_851100_NotesCashFlowStatement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cceae0f62263804c1e2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/842.000/NotesEffectsOfChangesInForeignExchangeRates",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_842000_NotesEffectsOfChangesInForeignExchangeRates",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ccfae0f62263804c1e3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/838.000/NotesEarningsPerShare",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_838000_NotesEarningsPerShare",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cd0ae0f62263804c1e4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.500/NotesInsuranceContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836500_NotesInsuranceContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cd0ae0f62263804c1e5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/836.200/NotesBorrowingCosts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_836200_NotesBorrowingCosts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cd3ae0f62263804c1e6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/835.110/NotesIncomeTaxes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_835110_NotesIncomeTaxes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cd4ae0f62263804c1e7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.480/NotesEmployeeBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834480_NotesEmployeeBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cd6ae0f62263804c1e8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/834.120/NotesShareBasedPaymentArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_834120_NotesShareBasedPaymentArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cdaae0f62263804c1e9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.900/NotesServiceConcessionArrangements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832900_NotesServiceConcessionArrangements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cddae0f62263804c1ea"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.800/NotesTransactionsInvolvingLegalFormOfLease",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832800_NotesTransactionsInvolvingLegalFormOfLease",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cddae0f62263804c1eb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.600/NotesLeases",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832600_NotesLeases",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cdeae0f62263804c1ec"
            },
            {
                "href": "http://xbrl.dcca.dk/role/832.410/NotesImpairmentOfAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_832410_NotesImpairmentOfAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cdfae0f62263804c1ed"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.710/ConstructionContracts",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831710_ConstructionContracts",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce1ae0f62263804c1ee"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.400/NotesGovernmentGrants",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831400_NotesGovernmentGrants",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce1ae0f62263804c1ef"
            },
            {
                "href": "http://xbrl.dcca.dk/role/831.110/NotesRevenue",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_831110_NotesRevenue",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce1ae0f62263804c1f0"
            },
            {
                "href": "http://xbrl.dcca.dk/role/827.570/NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_827570_NotesOtherProvisionsContingentLiabilitiesAndContingentAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce1ae0f62263804c1f1"
            },
            {
                "href": "http://xbrl.dcca.dk/role/826.380/NotesInventories",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_826380_NotesInventories",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce3ae0f62263804c1f2"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.900/NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825900_NotesNonCurrentAssetHeldForSaleAndDiscontinuedOperations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce3ae0f62263804c1f3"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.700/InterestsInOtherEntities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825700_InterestsInOtherEntities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ce4ae0f62263804c1f4"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.480/NotesConsolidatedAndSeparateFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825480_NotesConsolidatedAndSeparateFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0ceaae0f62263804c1f5"
            },
            {
                "href": "http://xbrl.dcca.dk/role/825.100/NotesInvestmentProperty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_825100_NotesInvestmentProperty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cedae0f62263804c1f6"
            },
            {
                "href": "http://xbrl.dcca.dk/role/824.180/NotesAgriculture",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_824180_NotesAgriculture",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cefae0f62263804c1f7"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.180/NotesIntangibleAssets",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823180_NotesIntangibleAssets",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cf0ae0f62263804c1f8"
            },
            {
                "href": "http://xbrl.dcca.dk/role/823.000/FairValueMeasurement",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_823000_FairValueMeasurement",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cf3ae0f62263804c1f9"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.390/NotesFinancialInstruments",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822390_NotesFinancialInstruments",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0cf9ae0f62263804c1fa"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.200/NotesExplorationForAndEvaluationOfMineralResources",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822200_NotesExplorationForAndEvaluationOfMineralResources",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d07ae0f62263804c1fb"
            },
            {
                "href": "http://xbrl.dcca.dk/role/822.100/NotesPropertyPlantAndEquipment",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_822100_NotesPropertyPlantAndEquipment",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d07ae0f62263804c1fc"
            },
            {
                "href": "http://xbrl.dcca.dk/role/819.100/NotesFirstTimeAdoption",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_819100_NotesFirstTimeAdoption",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d0aae0f62263804c1fd"
            },
            {
                "href": "http://xbrl.dcca.dk/role/818.000/NotesRelatedParty",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_818000_NotesRelatedParty",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d0bae0f62263804c1fe"
            },
            {
                "href": "http://xbrl.dcca.dk/role/817.000/NotesBusinessCombinations",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_817000_NotesBusinessCombinations",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d0dae0f62263804c1ff"
            },
            {
                "href": "http://xbrl.dcca.dk/role/816.000/NotesHyperinflationaryReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_816000_NotesHyperinflationaryReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d11ae0f62263804c200"
            },
            {
                "href": "http://xbrl.dcca.dk/role/815.000/NotesEventsAfterReportingPeriod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_815000_NotesEventsAfterReportingPeriod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d11ae0f62263804c201"
            },
            {
                "href": "http://xbrl.dcca.dk/role/813.000/NotesInterimFinancialReporting",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_813000_NotesInterimFinancialReporting",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d12ae0f62263804c202"
            },
            {
                "href": "http://xbrl.dcca.dk/role/811.000/NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_811000_NotesAccountingPoliciesChangesInAccountingEstimatesAndErrors",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d12ae0f62263804c203"
            },
            {
                "href": "http://xbrl.dcca.dk/role/810.000/NotesCorporateInformationAndStatementOfIFRSCompliance",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_810000_NotesCorporateInformationAndStatementOfIFRSCompliance",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d13ae0f62263804c204"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.600/NotesListOfAccountingPolicies",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800600_NotesListOfAccountingPolicies",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d15ae0f62263804c205"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.500/NotesListOfNotes",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800500_NotesListOfNotes",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d18ae0f62263804c206"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.400/NotesStatementOfChangesInEquityAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800400_NotesStatementOfChangesInEquityAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d1bae0f62263804c207"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.300/NotesStatementOfCashFlowsAdditionalDisclosures",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800300_NotesStatementOfCashFlowsAdditionalDisclosures",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d1cae0f62263804c208"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.200/NotesAnalysisOfIncomeAndExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800200_NotesAnalysisOfIncomeAndExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d1fae0f62263804c209"
            },
            {
                "href": "http://xbrl.dcca.dk/role/800.100/NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "roleId": "ifrs-dk_800100_NotesSubclassificationsOfAssetsLiabilitiesAndEquities",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_8NN.NN_2021-12-20/rol_ifrs-dk_8NN.NN_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d28ae0f62263804c20a"
            },
            {
                "href": "http://xbrl.dcca.dk/role/710.00/StatementOfChangesInNetAssetsAvailableForBenefits",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_71000_StatementOfChangesInNetAssetsAvailableForBenefits",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_710.00_2021-12-20/rol_ifrs-dk_710.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d39ae0f62263804c20b"
            },
            {
                "href": "http://xbrl.dcca.dk/role/610.00/StatementOfChangesInEquity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_61000_StatementOfChangesInEquity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_610.00_2021-12-20/rol_ifrs-dk_610.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d3bae0f62263804c20c"
            },
            {
                "href": "http://xbrl.dcca.dk/role/520.00/StatementOfCashFlowsIndirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_52000_StatementOfCashFlowsIndirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_520.00_2021-12-20/rol_ifrs-dk_520.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d3bae0f62263804c20d"
            },
            {
                "href": "http://xbrl.dcca.dk/role/510.00/StatementOfCashFlowsDirectMethod",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_51000_StatementOfCashFlowsDirectMethod",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_510.00_2021-12-20/rol_ifrs-dk_510.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d40ae0f62263804c20e"
            },
            {
                "href": "http://xbrl.dcca.dk/role/420.00/StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_42000_StatementOfComprehensiveIncomeOCIComponentsPresentedBeforeTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_420.00_2021-12-20/rol_ifrs-dk_420.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d43ae0f62263804c20f"
            },
            {
                "href": "http://xbrl.dcca.dk/role/410.00/StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_41000_StatementOfComprehensiveIncomeOCIComponentsPresentedNetOfTax",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_410.00_2021-12-20/rol_ifrs-dk_410.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d45ae0f62263804c210"
            },
            {
                "href": "http://xbrl.dcca.dk/role/330.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_33000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_330.00_2021-12-20/rol_ifrs-dk_330.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d46ae0f62263804c211"
            },
            {
                "href": "http://xbrl.dcca.dk/role/320.00/IncomeStatementByNatureOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_32000_IncomeStatementByNatureOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_320.00_2021-12-20/rol_ifrs-dk_320.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d46ae0f62263804c212"
            },
            {
                "href": "http://xbrl.dcca.dk/role/310.00/IncomeStatementByFunctionOfExpense",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_31000_IncomeStatementByFunctionOfExpense",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_310.00_2021-12-20/rol_ifrs-dk_310.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d48ae0f62263804c213"
            },
            {
                "href": "http://xbrl.dcca.dk/role/220.00/StatementOfFinancialPositionOrderOfLiquidity",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_22000_StatementOfFinancialPositionOrderOfLiquidity",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_220.00_2021-12-20/rol_ifrs-dk_220.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d49ae0f62263804c214"
            },
            {
                "href": "http://xbrl.dcca.dk/role/210.00/StatementOfFinancialPositionCurrentNonCurrent",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_21000_StatementOfFinancialPositionCurrentNonCurrent",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_210.00_2021-12-20/rol_ifrs-dk_210.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d50ae0f62263804c215"
            },
            {
                "href": "http://xbrl.dcca.dk/role/110.00/GeneralInformationAboutFinancialStatements",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_11000_GeneralInformationAboutFinancialStatements",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_110.00_2021-12-20/rol_ifrs-dk_110.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d59ae0f62263804c216"
            },
            {
                "href": "http://xbrl.dcca.dk/role/105.00/ManagementCommentary",
                "officialUri": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "roleId": "ifrs-dk_10500_ManagementCommentary",
                "relativePath": "http://ifrs/full_ifrs_dk/ifrs-dk_105.00_2021-12-20/rol_ifrs-dk_105.00_2021-12-20.xsd",
                "calculations": [],
                "isCustom": false,
                "id": "619e0d5aae0f62263804c217"
            }
        ],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [],
        "id": "619e0cc6ae0f62263804c1db"
    },
    {
        "schemaRef": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/ifrs-dk-cor_2021-12-20.xsd",
        "targetNamespace": null,
        "taxonomyGroups": [],
        "taxonomyId": "619e0329ae0f6226380475bc",
        "labels": [],
        "id": "619e0d5aae0f62263804c218"
    }
]