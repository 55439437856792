import { Container, Typography } from "@mui/material";
import { ElementReference } from "../../api/types";
import Grid from "@mui/material/Grid2";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";

interface ElementDetailedViewProps {
    element: ElementReference;
    isOption?: boolean;
}

const GridView = ({ label, value, size }: { label: string; value: string; size: number }) => {
    return (
        <Grid
            size={size}
            sx={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            <b style={{ marginRight: 5 }}>{label}</b>
            <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value}</span>
        </Grid>
    );
};


export const ElementDetailedView = ({
    element,
    isOption,
}: ElementDetailedViewProps) => {

    if (!element.extension && element.taxonomyElement) {
        return (
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    wordWrap: "break-word",
                    padding: 2,
                    color: "white",
                    backgroundColor: "rgb(0, 110, 74)",
                }}
            >
                <Grid container>
                    <Typography variant="body1">{element.taxonomyElement.name}</Typography>
                </Grid>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: 2,
            }}
        >
            <Grid container spacing={2}>
                <GridView label="Name" value={element?.extension?.name || ""} size={8} />
                <GridView label="Wider" value={element?.extension?.parentReference?.name || ""} size={4} />
                <GridView label="Company" value={element?.extension?.company?.name || ""} size={3} />
                <GridView label="Taxonomies" value={getTaxonomyNames(element?.extension?.taxonomies || []).join(", ") || ""} size={3} />
                <GridView label="Narrower" value={element?.extension?.name || ""} size={3} />
            </Grid>
        </Container>
    );
};
