import * as React from 'react';
import {
    Box,
    Select,
    MenuItem,
    Button,
    FormControl,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowModel, GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectTableProps } from '../../api/types';
import { t } from '@lingui/macro';

const languages = ['English', 'Spanish', 'French'];
const labels = ['Label1', 'Label2', 'Label3'];

interface SelectTableProps {
    data: selectTableProps[];
    setData: (newData: selectTableProps[]) => void;
}

export default function SelectTable({ data = [], setData }: SelectTableProps) {
    const handleAddRow = () => {
        const newRow: selectTableProps = { id: crypto.randomUUID(), lang: '', text: '', role: '' };
        setData([...data, newRow]);
    };

    const handleDeleteRow = (id: string) => {
        const newData = data.filter(row => row.id !== id);
        setData(newData);
    };

    const handleEditCellChange = (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow: selectTableProps = {
            lang: newRow.lang || oldRow.lang,
            text: newRow.text || oldRow.text,
            role: oldRow.role,
            id: oldRow.id,
        };

        const updatedData = data.map((row) =>
            row.id === updatedRow.id ? updatedRow : row
        );
        setData(updatedData);

        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'lang',
            headerName: t`Language`,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <FormControl fullWidth>
                    <Select
                        value={params.value}
                        onChange={(e) => params.api.setEditCellValue({ id: params.id, field: 'lang', value: e.target.value })}
                    >
                        {languages.map((language) => (
                            <MenuItem key={language} value={language}>
                                {language}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ),
        },
        {
            field: 'text',
            headerName: t`Label`,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <FormControl fullWidth>
                    <Select
                        value={params.value}
                        onChange={(e) => params.api.setEditCellValue({ id: params.id, field: 'text', value: e.target.value })}
                    >
                        {labels.map((label) => (
                            <MenuItem key={label} value={label}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ),
        },
        {
            field: 'addRow',
            headerName: '',
            renderHeader: () => (
                <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddRow}
                    sx={{ color: 'black', textTransform: 'none' }}
                >
                    {t`Add Row`}
                </Button>
            ),
            width: 150,
            sortable: false,
            filterable: false,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDeleteRow(params.id as string)}
                />,
            ],
        },
    ];

    return (
        <Box sx={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                processRowUpdate={handleEditCellChange}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                editMode="row"
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'orange',
                        color: 'black',
                    },
                }}
            />
        </Box>
    );
}
