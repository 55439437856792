import { useState } from "react";
import DataTable, { DataTableProps } from "./dataTable";
import { useGetQuery } from "../hooks/useGetQuery";
import { Container } from "@mui/material";

export type activeTabTableOptions = "customLabels" | "customHeaders" | "customTags" | "contexts";

interface DataTableWithFetchProps<T extends { id: string }> extends DataTableProps<T> {
    id: string;
    activeTab: activeTabTableOptions;
    endpoint: string;
}

export default function DataTableWithFetch<T extends { id: string }>({
    id,
    activeTab,
    endpoint,
    ...dataTableProps
}: DataTableWithFetchProps<T>) {
    const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
    const { data, loading } = useGetQuery<T[]>(
        `api/custom-objects/${endpoint}/${id}/${activeTab}?cb=${cacheBuster}`
    );

    return (
        <Container maxWidth={false}>
            <DataTable
                {...dataTableProps}
                data={data ?? []}
                loading={loading}
            />
        </Container>
    );
}