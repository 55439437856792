import { TableCell } from "@mui/material";
import { DataTableColumn } from "./dataTable";

interface DataTableCellProps<T> {
  row?: T;
  column?: DataTableColumn<T>;
  nested?: boolean;
  verticalAlign?: "top" | "bottom" | "middle";
}

export default function DataTableCell<T>({
  row,
  column,
  nested,
  verticalAlign,
}: DataTableCellProps<T>) {
  return (
    <TableCell
      sx={{
        overflowY: "auto",
        borderBottom: nested ? "unset" : "default",
        minWidth: column?.width ? column.width : undefined,
        maxWidth: column?.width ? column.width : undefined,
        whiteSpace: "normal",
        wordWrap: "break-word",
        verticalAlign: verticalAlign || "middle",
      }}
    >
      <>
        {column?.valueFormatter
          ? column?.valueFormatter(row as T)
          : row && column
            ? (row[column.key as keyof T] as string)
            : ""}
      </>
    </TableCell>
  );
}
