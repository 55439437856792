export const mapDataToTemplate = (template: any, data: Record<string, any>, selectTemplate: string): any => {
    const mappedTemplate = JSON.parse(JSON.stringify(template));

    const updateTemplateField = (templateField: any, dataValue: any): void => {
        if (templateField) {
            templateField.value = dataValue;
        }
    };

    const traverseAndUpdate = (templateSection: any, data: Record<string, any>): void => {
        Object.entries(templateSection).forEach(([key, templateField]) => {
            if (templateField && typeof templateField === 'object') {
                if ('fieldType' in templateField) {
                    const dataValue = data[key as keyof typeof data];
                    if (dataValue !== undefined) {
                        updateTemplateField(templateField, dataValue);
                    }
                } else {
                    traverseAndUpdate(templateField, data);
                }
            }
        });
    };

    const addLangToLanguage = (templateSection: any, data: Record<string, any>): void => {
        Object.keys(templateSection).forEach((key) => {
            const templateField = templateSection[key];
            if (templateField && typeof templateField === 'object') {
                if (key === 'language' && 'lang' in data) {
                    templateField.value = data.lang;
                } else {
                    addLangToLanguage(templateField, data);
                }
            }
        });
    };

    const updateReferences = (key: string, navBarName: string): void => {
        if (key in data) {
            mappedTemplate[key] = data[key].map((reference: any) => ({ ...reference }));
            mappedTemplate[key].navBarName = navBarName;
        }
    };

    Object.keys(mappedTemplate).forEach((sectionKey) => {
        const section = mappedTemplate[sectionKey];
        if (section && typeof section === 'object') {
            traverseAndUpdate(section, data);
            addLangToLanguage(section, data);
        }
    });

    updateReferences('dimensionValueReferences', 'Dimension Values');
    updateReferences('additionalElementReferences', 'Additional Elements');
    updateReferences('extensionStructureReferences', 'Extension Structure');
    updateReferences('hierarchyElementReferences', 'Extension');

    if ('labels' in data) {
        if (selectTemplate === "customDimensions") {
            mappedTemplate.dimensionLabels = data.labels;
            mappedTemplate.dimensionLabels.navBarName = 'Dimension Labels';
        } else {
            mappedTemplate.customLabels = data.labels;
            mappedTemplate.customLabels.navBarName = 'Custom Labels';
        }
    }

    if ('taxonomies' in data) {
        mappedTemplate.taxonomies = data.taxonomies;
        mappedTemplate.taxonomies.navBarName = 'Taxonomies';
        localStorage.setItem('selectedTaxonomies', JSON.stringify(data.taxonomies));
    }

    return mappedTemplate;
};

export const mapTemplateToData = (template: any, id: string | null, type: string): Record<string, any> => {
    const data: Record<string, any> = {};

    const extractFieldValue = (templateField: any): any => {
        if (templateField && 'value' in templateField) {
            return templateField.value;
        }
        return null;
    };

    const capitalizeFirstLetter = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const traverseAndExtract = (templateSection: any): void => {
        Object.entries(templateSection).forEach(([key, templateField]) => {
            if (templateField && typeof templateField === 'object') {
                if ('fieldType' in templateField) {
                    data[capitalizeFirstLetter(key)] = extractFieldValue(templateField);
                    if (template.id === 'labels' && key === 'label') {
                        data.SearchLabel = data[capitalizeFirstLetter(key)];
                    }
                } else {
                    traverseAndExtract(templateField);
                }
            }
        });
    };

    const extractReferences = (key: string, templateSection: any): void => {
        if (Array.isArray(templateSection[key])) {
            data[capitalizeFirstLetter(key)] = templateSection[key].map((reference: any) => ({ ...reference }));
        }
    };

    Object.keys(template).forEach((sectionKey) => {
        const section = template[sectionKey];
        if (section && typeof section === 'object') {
            traverseAndExtract(section);
        }
    });

    extractReferences('dimensionValueReferences', template);
    extractReferences('additionalElementReferences', template);
    extractReferences('extensionStructureReferences', template);
    extractReferences('hierarchyElementReferences', template);

    if (template.elementReference) {
        data.Element = template.elementReference;
    }

    if (template.dimensionLabels) {
        data.Labels = template.dimensionLabels;
    } else if (template.customLabels) {
        data.Labels = template.customLabels;
    }

    if (template.taxonomies) {
        data.Taxonomies = template.taxonomies;
    }

    if (id) {
        data.Id = id;
    }

    if (type === "labels" || type === "Headers" || type === "table-items") {

        const isHeader = type === "labels" ? false : type === "Headers" ? true : type === "table-items" ? false : null;
        const whatType = type === "labels" ? "Row" : type === "Headers" ? "Header" : type === "table-items" ? "TableItem" : null;

        data.IsCustomHeader = isHeader
        data.Type = whatType
    }

    return data;
};
