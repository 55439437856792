import { Trans, t } from "@lingui/macro";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getActiveTaxonomySchemes, updateTaxonomy } from "../../api/taxonomies";
import {
  ApiDetailedTaxonomy,
  ApiSchemes,
  MultiLingualElement,
} from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DataTable from "../dataTable";

export interface TaxonomyDetailsDialogProps {
  open: boolean;
  taxonomyId: string | undefined;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => void;
}

const DISPLAY_TO_BACKEND_MAPPING: { [key: string]: string } = {
  "Euro Fillings": "EuroFilling",
};

const BACKEND_TO_DISPLAY_MAPPING: { [key: string]: string } = {
  "EuroFilling": "Euro Fillings",
};

const TaxonomyDetailsDialog = ({
  open,
  taxonomyId,
  onClose,
}: TaxonomyDetailsDialogProps) => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());

  const [updating, setUpdating] = useState(false);
  const [originalTitle, setOriginalTitle] = useState("");

  const { data, loading, error, setData } = useGetQuery<ApiDetailedTaxonomy>(
    `/api/taxonomies/${taxonomyId}?cb=${cacheBuster}`,
    taxonomyId === undefined
  );

  const [permissionScheme, setPermissionScheme] = useState<
    ApiDetailedTaxonomy["permissionScheme"] | null
  >(null);

  useEffect(() => {
    if (data) {
      setPermissionScheme(data.permissionScheme || null);
    }
  }, [data]);

  const [allPermissionSchemes, setAllPermissionSchemes] = useState<
    ApiSchemes[]
  >([]);

  useEffect(() => {
    const fetchPermissionSchemes = async () => {
      const resp = await getActiveTaxonomySchemes(cacheBuster);
      setAllPermissionSchemes(resp.data);
    };
    if (data && allPermissionSchemes.length === 0) {
      fetchPermissionSchemes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [initialData, setInitialData] = useState<{
    type: string;
    systemName: string | undefined;
    permissionScheme: ApiDetailedTaxonomy["permissionScheme"];
    validatingAuthority: string | null;
  }>({
    type: "",
    systemName: "",
    validatingAuthority: null,
    permissionScheme: {
      id: "",
      name: "",
    },
  });

  if (data && !initialData.systemName) {
    setInitialData({
      type: BACKEND_TO_DISPLAY_MAPPING[data.type] || data.type,
      systemName: data.systemName,
      permissionScheme: data.permissionScheme,
      validatingAuthority: data.validatingAuthority,
    });
  }

  const handleFieldValueChange = (
    key: keyof ApiDetailedTaxonomy | string | undefined,
    value: string
  ) => {
    if (key) {
      if (!originalTitle) {
        setOriginalTitle(data?.systemName || "");
      }
      const updated = { ...data };
      if (key === "type") {
        const mappedValue = DISPLAY_TO_BACKEND_MAPPING[value] || value;
        (updated as any)[key] = mappedValue;
      } else {
        (updated as any)[key] = value;
      }
      setData(updated as ApiDetailedTaxonomy);
    }
  };

  const handleSubmit = async () => {
    setUpdating(true);
    try {
      if (data) {
        await updateTaxonomy(taxonomyId as string, {
          systemName: data?.systemName,
          permissionScheme: permissionScheme?.id || null,
          type: data?.type,
          validatingAuthority:
            data?.type === "EuroFilling" && !data?.validatingAuthority
              ? null
              : data?.validatingAuthority || null,
        });
      }
      onClose("success", "Taxonomy updated successfully");
    } catch (ex: any) {
      onClose("error", ex.response.data.IsTranslated
        ? ex.response.data.Exceptions[0]
        : t`Could not update taxonomy. Try again later`);
    } finally {
      setUpdating(false);
      setCacheBuster(crypto.randomUUID());
    }
  };

  interface Icolumns {
    label: string;
    key: string;
    value: string | undefined;
    select?: boolean;
    options?: string[];
    isCheckBox?: boolean;
    disabled?: boolean;
    onchange?: boolean;
  }

  const ColumnItems: Icolumns[] = [
    {
      label: t`Id:`,
      key: "id",
      value: data?.id,
      disabled: true,
    },
    {
      label: t`System Name:`,
      key: "systemName",
      value: data?.systemName,
      onchange: true,
    },
    {
      label: t`Package Name:`,
      key: "packageName",
      value: data?.packageName,
      disabled: true,
    },
    {
      label: t`Identifier:`,
      key: "identifier",
      value: data?.identifier,
      disabled: true,
    },
    {
      label: t`Full Package Name:`,
      key: "fullPackageName",
      value: data?.fullPackageName,
      disabled: true,
    },
    {
      label: t`System Type:`,
      key: "type",
      value: BACKEND_TO_DISPLAY_MAPPING[data?.type ?? ""] || data?.type,
      select: true,
      options: ["Default", "Euro Fillings"],
      onchange: true,
    },
    {
      label: t`Lisence Name:`,
      key: "licenseName",
      value: data?.license?.name,
      disabled: true,
    },
    {
      label: t`Validating Authority:`,
      key: "validatingAuthority",
      value: data?.validatingAuthority || "",
      select: true,
      options: ["", "EBA", "EIOPA", "DNB", "SBR"],
      onchange: true,
    },
    {
      label: t`Lisence Href:`,
      key: "licenseHref",
      value: data?.license?.href,
      disabled: true,
    },
  ];

  const publisherList = [
    {
      label: t`URL:`,
      key: "url",
      value: data?.publisher?.url,
      disabled: true,
    },
    {
      label: t`Country:`,
      key: "country",
      value: data?.publisher?.country,
      disabled: true,
    },
  ];

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth="lg"
      sx={{ overflow: "auto", maxHeight: 1200, minHeight: 1000 }}
      onClose={(reason) => {
        if (reason !== "backdropClick" || "escapeKeyDown") {
          onClose("none");
        }
      }}
    >
      {loading && (
        <Box sx={{ minWidth: 500, minHeight: 500 }}>
          <CircularProgress
            sx={{ position: "absolute", left: "50%", top: "50%" }}
          />
        </Box>
      )}
      {!loading && error && !data && (
        <Alert severity="error">
          <Trans>Could not load taxonomy details. Please try again later</Trans>
        </Alert>
      )}
      {!loading && !error && data !== undefined && data && (
        <>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>{originalTitle || data.systemName}</Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ pt: 2, pb: 2 }}>
                  <Typography variant="h6">{t`General Information`}</Typography>
                  <Divider />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {ColumnItems.map((item) => (
                <Box sx={{ flex: "1 1 50%" }} key={item.key}>
                  <TextField
                    label={item.label}
                    value={item.value}
                    disabled={item.disabled}
                    select={item.select}
                    onChange={
                      item.onchange && data
                        ? (e) => handleFieldValueChange(item.key, e.target.value)
                        : undefined
                    }
                    fullWidth
                    margin="normal"
                    sx={{
                      p: 1,
                    }}
                    variant="standard"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {item.select &&
                      item.options?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </TextField>
                </Box>
              ))}
              <Box sx={{ flex: "1 1 50%" }}>
                <TextField
                  label={t`Permission Scheme`}
                  value={permissionScheme?.name || ""}
                  select
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPermissionScheme(null);
                    } else {
                      const selected = allPermissionSchemes.find(
                        (item) => item.name === e.target.value
                      );
                      if (selected) {
                        setPermissionScheme({
                          id: selected.id,
                          name: selected.name,
                        });
                      }
                    }
                  }}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value=""></option>
                  {allPermissionSchemes.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Divider />
            {data === undefined ? null : (
              <React.Fragment>
                <Accordion
                  elevation={1}
                  sx={{
                    maxHeight: 500,
                    borderBottom: "1px solid black",
                    overflow: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t`Publisher`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {publisherList.map((item) => (
                      <Box sx={{ flex: "1 1 50%" }} key={item.key}>
                        <TextField
                          label={item.label}
                          value={item.value}
                          disabled={item.disabled}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Box>
                    ))}
                    {!data.publisher?.names ? null : (
                      <DataTable<MultiLingualElement>
                        data={data.publisher.names}
                        columns={[
                          {
                            header: {
                              label: t`Text`,
                              id: "text",
                            },
                            key: "text",
                            width: 200,
                          },
                          {
                            header: {
                              label: t`Language`,
                              id: "language",
                            },
                            key: "language",
                          },
                        ]}
                        noWidth
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  elevation={1}
                  sx={{
                    maxHeight: 500,
                    borderBottom: "1px solid black",
                    overflow: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t`Names`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!data.names ? null : (
                      <DataTable<MultiLingualElement>
                        data={data.names}
                        columns={[
                          {
                            header: {
                              label: t`Text`,
                              id: "text",
                            },
                            key: "text",
                            width: 200,
                          },
                          {
                            header: {
                              label: t`Language`,
                              id: "language",
                            },
                            key: "language",
                          },
                        ]}
                        noWidth
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  elevation={1}
                  sx={{
                    maxHeight: 500,
                    borderBottom: "1px solid black",
                    overflow: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t`Descriptions`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableHead sx={{ backgroundColor: "#072a44" }}>
                          <TableRow>
                            <TableCell
                              sx={{ color: "white" }}
                            >{t`Text`}</TableCell>
                            <TableCell
                              sx={{ color: "white" }}
                            >{t`Language`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!data.descriptions ? (
                            <Typography>{t`No descriptions found`}</Typography>
                          ) : (
                            data.descriptions.map((item, index) => (
                              <React.Fragment key={index}>
                                <CustomTableRow item={item} />
                              </React.Fragment>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  elevation={1}
                  sx={{
                    maxHeight: 500,
                    borderBottom: "1px solid black",
                    overflow: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t`Entry Points`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {!data.entryPoints ? (
                            <Typography>{t`No entry points found`}</Typography>
                          ) : (
                            data.entryPoints.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item}</TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => onClose("none")}>
              <Trans>Close</Trans>
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={
                (initialData?.systemName === data.systemName &&
                  initialData?.type === data.type &&
                  data.permissionScheme?.id === permissionScheme?.id &&
                  initialData?.validatingAuthority === data.validatingAuthority) ||
                updating
              }
            >
              {!updating && <Trans>Update</Trans>}
              {updating && <CircularProgress size={25} />}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TaxonomyDetailsDialog;

function CustomTableRow({ item }: any) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <TableRow sx={{ maxWidth: 900 }}>
        <CustomTableCell
          item={item}
          isExpanded={isExpanded}
          onExpandClick={handleExpandClick}
          sx={{ width: 720 }}
        />
        <TableCell sx={{ width: 180 }}>{item.language}</TableCell>
      </TableRow>
      <TableRow sx={{ display: isExpanded ? "block" : "none" }}>
        <TableCell colSpan={2} sx={{ maxWidth: 800 }}>
          <Collapse in={isExpanded}>
            <Typography sx={{ fontSize: 12 }}>{item.text}</Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CustomTableCell({ item, isExpanded, onExpandClick }: any) {
  return (
    <TableCell>
      <Typography noWrap variant="body2" component="div">
        {item.text.length > 100 ? (
          <>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{item.text.slice(0, 100)}</span>
              <span onClick={onExpandClick} style={{ cursor: "pointer" }}>
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </span>
            </span>
          </>
        ) : (
          <span>{item.text}</span>
        )}
      </Typography>
    </TableCell>
  );
}